$className: spark-drawer;

.spark-drawer {

    &__title {

        &[class] {
            cursor: pointer;
        }

        .open {
            display: none;
        }

        &:hover .open {
            text-decoration: none;
        }
    }

    &__container {
        max-height: 0;
        margin-top: -1px;
        overflow: hidden;
        transition: all $speed--400 ease;

        &.active {
            max-height: 10000px;
        }
    }

    &__title[aria-expanded=true] + &__container {
        max-height: 10000px;
    }

    &__content.#{$className}--bar {
        padding: $spacing--50 0;
        margin: 0 auto;
        width: lumen-columns(4,300);

        @media screen and (min-width: $breakpoint--400) {
            width: lumen-columns(10, 400);
        }
        @media screen and (min-width: $breakpoint--500) {
            width: lumen-columns(10, 500);
        }
        @media screen and (min-width: $breakpoint--600) {
            width: lumen-columns(10, 600);
        }
        @media screen and (min-width: $breakpoint--700) {
            width: lumen-columns(10, 700);
        }
    }

    &--bar {

        &.#{$className} {
            &__title[class],
            &__container[class]:last-child {
                margin-bottom: $spacing--50;

                &:first-of-type {
                    margin-top: $spacing--100;
                }
            }

            &__title {

                &[class] {
                    display: flex;
                    position: relative;
                    min-height: $spacing--600;
                    color: $color__black--800;

                    &:hover {
                        color: $color__black--700;
                        text-decoration: none;
                    }
                }

                &[aria-expanded=true] {
                    font-weight: 700;
                }

                &:last-of-type {
                    margin-bottom: $spacing--400;
                }
            }
        }

        .#{$className} {

            &__title[class], &__container[class] {
                margin-bottom: 0;
            }

            &__title {
                &[class] {
                    background: none;
                    margin-bottom: 0;
                    border-bottom: 1px solid $color__black--200;
                    border-top: 1px solid $color__black--200;
                }

                &__text[class] {
                    display: inline-block;
                    flex: 1 1 100%;
                    background: $color__black--200;
                    padding: $spacing--200 $spacing--300;

                }

                &__trigger {
                    transition: background $speed--400 ease;
                    color: $color__black--800;
                    margin-left: $spacing--25;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color__black--200;
                    padding: $spacing--50 $spacing--300;
                    min-width: 40px;

                    &__icon {
                        text-align: center;

                        &:before {
                            display: inline-block;
                            transform: rotate(0deg);
                            transition: all $speed--400 ease;
                        }
                    }
                }
            }
            &__content {
                padding-left: 0;
                padding-right: 0;
            }

            &--bar {

                &:first-of-type {
                    border-top: 0;

                    &[class] {
                        margin-top: 0;
                        border-bottom: 0;
                    }
                }

                &:last-of-type {
                    border-bottom: 0;
                }
                .#{$className} {
                    &__title__text,
                    &__title__trigger {
                        background-color: $color__white;
                    }
                }

                &[aria-expanded=true] {
                    border-bottom: 1px solid $color__black--200;
                }
            }
        }
    }

    &--link {
        .#{$className} {
            &__title {
                &__trigger {
                    display: inline-block;
                    &__icon {
                        &:before {
                            display: inline-block;
                            transition: all $speed--400 ease;
                        }
                    }
                }
            }
        }
    }

    &__title[aria-expanded=true] {
        .closed {
            display: none;
        }
        .open {
            display: inline-block;
        }

        &:hover {
            .open {
                text-decoration: underline;
            }
        }

        &.#{$className}--bar:hover {
            .open {
                text-decoration: none;
            }
        }

        .#{$className}__title__trigger {
            background: $color__white;

            &__icon {

                &:before {
                    transform: rotate(-90deg);
                    content: "\ea02";
                    width: 18px; // required since the arrow and the close are different sizes
                }

            }
        }
    }
}
