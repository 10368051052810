.spark-fieldset {
    border: 0;
    margin: $spacing--600 0;
    padding: 0;

    legend {
        font-size: $font__size--900;
        margin-bottom: $spacing--400;
    }
}
