$dir-start: left !default;
$dir-end: right !default;
@mixin lumen-frame--narrow() {
    // setup the general widths and margins.
    width: lumen-columns(4,300);
    margin-#{$dir-start}: lumen-gutter(300,true);

    @media (min-width: $breakpoint--400){
        width: lumen-columns(10,400);
        margin-#{$dir-start}: lumen-columns(1,400, true) + lumen-gutter(400,true);
    }

    @media (min-width: $breakpoint--500){
        width: lumen-columns(8,500);
        margin-#{$dir-start}: lumen-columns(2,500, true) + lumen-gutter(500,true);
    }

    @media (min-width: $breakpoint--600){
        width: lumen-columns(6,600);
        margin-#{$dir-start}: lumen-columns(3,600, true) + lumen-gutter(600,true);
    }

    @media (min-width: $breakpoint--700){
        width: lumen-columns(6,700);
        margin-#{$dir-start}: lumen-columns(5,700, true) + lumen-gutter(700,true);
    }
    @media print {
        width: 94%;
        margin-left: auto;
        margin-right: auto;
    }
}
