//content will breakout 1 column width on the start side (left side in ltr language, right side in rtl language)
@mixin breakout-start() {
    margin-#{$dir-start}: auto;
    margin-#{$dir-end}: auto;

    @media screen and (min-width: $breakpoint--400) {
        margin-#{$dir-start}: (lumen-columns(1,400, true)) * -1;
        margin-#{$dir-end}: lumen-gutter(400);
    }

    @media screen and (min-width: $breakpoint--500) {
        margin-#{$dir-start}: (lumen-columns(1,500, true)) * -1;
        margin-#{$dir-end}: lumen-gutter(500);
    }

    @media screen and (min-width: $breakpoint--600) {
        margin-#{$dir-start}: (lumen-columns(1,600, true)) * -1;
        margin-#{$dir-end}: lumen-gutter(600);
    }

    @media screen and (min-width: $breakpoint--700) {
        margin-#{$dir-start}: (lumen-columns(1,700, true)) * -1;
        margin-#{$dir-end}: lumen-gutter(700);
    }
}

//content will breakout 1 column width on the end side (right side in ltr language, left side in rtl language)
@mixin breakout-end() {
    margin-#{$dir-start}: auto;
    margin-#{$dir-end}: auto;

    @media screen and (min-width: $breakpoint--400) {
        margin-#{$dir-start}: lumen-gutter(400);
        margin-#{$dir-end}: (lumen-columns(1,400, true)) * -1;
    }

    @media screen and (min-width: $breakpoint--500) {
        margin-#{$dir-start}: lumen-gutter(500);
        margin-#{$dir-end}: (lumen-columns(1,500, true)) * -1;
    }

    @media screen and (min-width: $breakpoint--600) {
        margin-#{$dir-start}: lumen-gutter(600);
        margin-#{$dir-end}: (lumen-columns(1,600, true)) * -1;
    }

    @media screen and (min-width: $breakpoint--700) {
        margin-#{$dir-start}: lumen-gutter(700);
        margin-#{$dir-end}: (lumen-columns(1,700, true)) * -1;
    }
}

@mixin breakout-both() {
    margin-#{$dir-start}: auto;
    margin-#{$dir-end}: auto;

    @media screen and (min-width: $breakpoint--400) {
        margin-#{$dir-start}: (lumen-columns(1,400, true)) * -1;
        margin-#{$dir-end}: (lumen-columns(1,400, true)) * -1;
    }

    @media screen and (min-width: $breakpoint--500) {
        margin-#{$dir-start}: (lumen-columns(1,500, true)) * -1;
        margin-#{$dir-end}: (lumen-columns(1,500, true)) * -1;
    }

    @media screen and (min-width: $breakpoint--600) {
        margin-#{$dir-start}: (lumen-columns(1,600, true)) * -1;
        margin-#{$dir-end}: (lumen-columns(1,600, true)) * -1;
    }

    @media screen and (min-width: $breakpoint--700) {
        margin-#{$dir-start}: (lumen-columns(1,700, true)) * -1;
        margin-#{$dir-end}: (lumen-columns(1,700, true)) * -1;
    }
}
@mixin rtl(){
    direction:rtl;
    unicode-bidi: embed;
}