// lumen-sass-icons
// this file sets all the variables, placeholders and mixins for using the icons from Lumen

// ICONS MAP
// TODO: This was semi-manually built. Need to find a way to generate this list better. This works for now.
$icons: (
    menu: '\e900',
    settings: '\e901',
    account: '\e902',
    secure: '\e903',
    home: '\e904',
    international: '\e905',
    info: '\e906',
    help: '\e907',
    warning: '\e908',
    refresh: '\e909',
    edit: '\e90a',
    delete: '\e90b',
    leave: '\e90c',
    take-image: '\e90d',
    take-image-active: '\e90e',
    show: '\e90f',
    history: '\e910',
    translation: '\e911',
    list: '\ea00',
    close: '\ea01',
    close-open: '\ea02',
    add: '\ea03',
    add-open: '\ea04',
    more: '\ea05',
    more-closed: '\ea06',
    location: '\ea07',
    search: '\ea08',
    search-open: '\ea09',
    open: '\ea0a',
    radio-inner: '\ea0b',
    check: '\ea0c',
    check-active: '\ea0d',
    check-open: '\ea0e',
    check-box: '\ea0f',
    check-box-open: '\ea10',
    down: '\ea11',
    down-open: '\ea12',
    up: '\ea13',
    up-open: '\ea14',
    left: '\ea15',
    left-open: '\ea16',
    right: '\ea17',
    right-open: '\ea18',
    page-left: '\ea19',
    page-right: '\ea1a',
    dont: '\ea1b',
    download: '\ea1c',
    download-ios: '\ea1d',
    pointer: '\ea1e',
    play: '\eb00',
    pause: '\eb01',
    play-open: '\eb02',
    pause-open: '\eb03',
    volume: '\eb04',
    mute: '\eb05',
    volume-open: '\eb06',
    mute-open: '\eb07',
    next: '\eb08',
    previous: '\eb09',
    forward-ten: '\eb0a',
    back-ten: '\eb0b',
    shuffle: '\eb0c',
    expand: '\eb0d',
    collapse: '\eb0e',
    favorite: '\ec00',
    favorite-active: '\ec01',
    rate: '\ec02',
    rate-active: '\ec03',
    share: '\ec04',
    share-ios: '\ec05',
    comment: '\ec06',
    comment-add: '\ec07',
    current-location: '\ec09',
    current-location-ios: '\ec0a',
    bookmark: '\ec0b',
    bookmark-active: '\ec0c',
    rss-feed: '\ee00',
    facebook: '\ee01',
    google-plus: '\ee02',
    instagram: '\ee03',
    pinterest: '\ee04',
    twitter: '\ee05',
    youtube: '\ee06',
    ok: '\ee0a',
    vk: '\ee09',
    mail: '\ee07',
    print: '\ee08',
    'note': '\ed00', // this must be quoted because there is a libsass bug with the word 'not' being used as the key
    audio: '\ed01',
    calling: '\ed02',
    calendar: '\ed03',
    media: '\ed04',
    video: '\ed05',
    image: '\ed06',
    directory: '\ed07',
    book: '\ed08',
    radio: '\ed09',
    social: '\ec08',
    kakao: '\ee0b',
    whatsapp: '\ee0c'
);
$iconColors:(
    facebook: $color--facebook,
    twitter: $color--twitter,
    pinterest: $color--pinterest,
      google: $color--google,
    google-plus: $color--google,
    instagram: $color--instagram,
    youtube: $color--youtube,
    ok: $color--ok,
    vk: $color--vk,
    kakao: $color--kakao,
    whatsapp: $color--whatsapp
);

// override default values from lumen-icon package
$icon__gap--graphic-text: .2em;
$icon__gap--icon-icon: 1em;
$icon__font__family: $font__family--icon;

// this defines a placeholder for each icon with the correct unicode value and an optional color. Placeholders will be available to inject wherever as: @extend %icon--search;
@each $icon, $content in $icons {
    %icon--#{$icon} {
        &:before {
      content: "#{$content}";
            @if map-get($iconColors,$icon) {
                color:map-get($iconColors,$icon);
            }
        }
    }
}

// mixin for generating icon classes. If you don't pass anything in, it will generate all icons.  If you pass in a list, it will only generate those icons
@mixin icons($specific-icons:$icons, $prefix:"lumen-icon__graphic--"){ // default to all icons if none are pased in
    @each $icon, $content in $specific-icons {
        // this creates icon classes using the placeholders created above
        .#{$prefix}#{$icon} {
            @extend %icon--#{$icon};
        }
    }
}

@mixin icon--pseudo-style(){
    font-family: $icon__font__family;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    height: 1em;
    max-width: 1em;
    border-radius: 1em;
    position: relative;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
