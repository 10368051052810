.spark-multifile-upload {

    &__add-button.lumen-button[class] {
        margin-top: $spacing--300;
    }

    .spark-input {
        margin-bottom: $spacing--200;
    }
}
