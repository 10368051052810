.lumen-media-block {
    margin: $spacing--600 0;
    position: relative;
    width: 100%; //fixes issue where video shrinks to match width of caption
    // ******Included in spec but not ready to implement yet - may want to add modifier for breakout-both*****
    // @include breakout-both();

    @media (min-width: $breakpoint--600) {
      margin-bottom: $spacing--700;
      margin-top: $spacing--700;
    }

    // ******Included in spec but not ready to implement yet - may want to add modifier for breakout-both*****
    // & .lumen-video {
    //   margin-#{$dir-start}: lumen-gutter(300,true)*-1;
    //   width: 100vw;
    //
    //   @media (min-width: $breakpoint--400) {
    //     margin-#{$dir-start}: 0;
    //     width: 100%;
    //   }
    // }

    .lumen-media-block &__caption {
        color: $color--black__600;
        font-size: $font__size--200;
        font-weight: 600;
        line-height: $font__line-height--500;
        padding-top: $spacing--200;

        @media (min-width: $breakpoint--400) {
          font-size: $font__size--300;
        }
    }

    span.lumen-media-block__caption__title {
        display: block;
        color: $color--black__800;
        font-weight: 700;
    }

    &--breakout-end {
        @include breakout-end;
        float: $dir-end;
        margin-top: 0;
        margin-bottom: $spacing--400;
        text-align: center;
        width: 100%;
        @media screen and (min-width: $breakpoint--400) {
            text-align: left;
            margin-bottom: lumen-gutter(400);
            width: lumen-columns(3, 400);
        }
        @media screen and (min-width: $breakpoint--500) {
            width: lumen-columns(3, 500);
        }
        @media screen and (min-width: $breakpoint--600) {
            width: lumen-columns(3, 600);
        }
        @media screen and (min-width: $breakpoint--700) {
            width: lumen-columns(3, 700);
        }
    }

    &--breakout-start {
        @include breakout-start;
        float: $dir-start;
        margin-top: 0;
        margin-bottom: $spacing--400;
        text-align: center;
        width: 100%;
        @media screen and (min-width: $breakpoint--400) {
            text-align: left;
            margin-bottom: lumen-gutter(400);
            width: lumen-columns(3, 400);
        }
        @media screen and (min-width: $breakpoint--500) {
            width: lumen-columns(3, 500);
        }
        @media screen and (min-width: $breakpoint--600) {
            width: lumen-columns(3, 600);
        }
        @media screen and (min-width: $breakpoint--700) {
            width: lumen-columns(3, 700);
        }
    }

    .spark-drawer__title {
        position: absolute;
        right: 0;
        color: $color__black--800;
        margin-top: $spacing--50;

        .lumen-icon__graphic:before {
            transition: all $speed--300 ease;
            transform: rotate(0deg);
        }

        &:hover {
            text-decoration: none;

        }
    }
    .spark-drawer__title[aria-expanded=true] .lumen-icon .lumen-icon__graphic {
        @extend %icon--close;
        &:before {
            transform: rotate(180deg);
        }
    }
    .spark-drawer__content {
        margin: $spacing--200 0;
        padding-bottom: $spacing--200;
        padding-right: $spacing--600;
        border-bottom: 1px solid $color__black--500;
    }

    .spark-drawer__container + figcaption {
        margin-right: lumen-columns(1, 300);

        @media screen and (min-width: $breakpoint--400) {
            margin-right: lumen-columns(1, 400);
        }
        @media screen and (min-width: $breakpoint--500) {
            margin-right: lumen-columns(1, 500);
        }
        @media screen and (min-width: $breakpoint--600) {
            margin-right: lumen-columns(1, 600);
        }
        @media screen and (min-width: $breakpoint--700) {
            margin-right: lumen-columns(1, 700);
        }
    }
}

@mixin lumen-media-block--full() {
    > .lumen-media-block {
        width: lumen-columns(4,300);
        margin-#{$dir-start}: 0;

        @media (min-width: $breakpoint--400){
            width: lumen-columns(10,400);
            margin-#{$dir-start}: lumen-columns(1,400, true);
        }

        @media (min-width: $breakpoint--500){
            width: lumen-columns(8,500);
            margin-#{$dir-start}: lumen-columns(2,500, true);
        }

        @media (min-width: $breakpoint--600){
            width: lumen-columns(6,600);
            margin-#{$dir-start}: lumen-columns(3,600, true);
        }

        @media (min-width: $breakpoint--700){
            width: lumen-columns(6,700);
            margin-#{$dir-start}: lumen-columns(3,700, true);
        }
    }
}
