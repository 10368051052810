@import "lumen-dynamic-layout-mixins";

@include lumen-dynamic-layout();

.lumen-frame--narrow {
    @include lumen-dynamic-layout--2-column(narrow);
    @include lumen-dynamic-layout--3-column(narrow);
    @include lumen-dynamic-layout--4-column(narrow);

    .lumen-dynamic-layout--masonry[class] {
      @include lumen-fullbleed-background(narrow);
    }
}

.lumen-frame--full {
    @include lumen-dynamic-layout--2-column(full);
    @include lumen-dynamic-layout--3-column(full);
    @include lumen-dynamic-layout--4-column(full);

    .lumen-dynamic-layout--masonry {
        @include lumen-fullbleed-background(full);
    }
}

//split frame --major
[class$="--major"] {
    @include lumen-dynamic-layout--2-column(split);
    @include lumen-dynamic-layout--3-column(split);
    @include lumen-dynamic-layout--4-column(split);
}
