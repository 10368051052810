@use "sass:math";

.acp-home-template {
    &__content {
        @include lumen-frame--split();
    }

    .lumen-dynamic-layout:nth-child(2) {
        .lumen-tile__image-wrapper {
            width: math.div(lumen-columns(1, 300), 2);
            max-width: 50px;

            @media (min-width: $breakpoint--400) {
                width: lumen-columns(1, 400) * 1.5;
            }
        }
    }
}
