// lumen-sass-fonts
// this file sets all the variables, placeholders and mixins for using the fonts from Lumen

// language specific defaults, to be overwritten by [lang].scss files if available
$font__family--sans--language: null !default;
$font__family--serif--language: null !default;
// percentages don't work, so needs to be a float number (1.2 = 120%)
$font__size--increase: 1 !default;
$font__line-height--increase: $font__size--increase !default;

$font__style--italic: italic !default;
$font__style--italic-before: null !default;
$font__style--italic-after: null !default;
$font__weight--semi-bold: 600 !default;
$font__weight--bold: 700 !default;

$html__font-size: 62.5% * $font__size--increase !default;
$font__encoding: null !default;
$text__transform--uppercase: uppercase;
$font-injection: null !default;
$isRTL: null !default;
$emAsBold: null !default;

//FONT DEFINITIONS

$font__family--sans: "Open Sans", "Zoram", $font__family--sans--language, "noto sans", Helvetica, Arial, sans-serif;
$font__family--slab: "Helam", $font__family--sans--language Helvetica, Arial, sans-serif;
//$font__family--serif: Palatino, "Palatino Linotype", "Pahoran", "Georgia", "Times New Roman", serif;
$font__family--serif: $font__family--slab;
$font__family--serif-gvsg: Baskerville, Palatino, "Palatino Linotype", "Pahoran", $font__family--sans--language, "Georgia", "Times New Roman", serif; // DO NOT USE unless your full template has been approved by the VIO
$font__family--monospace: 'Courier New', Courier, monospace;
$font__family--icon: Gideon;

// setting this as !default so that if there needs to be more specific adjustments beyond $font__size--increase for languages it can be done at the individual size level
$font__size--100: 1.0rem !default;
$font__size--200: 1.2rem !default; // formerly xxs
$font__size--300: 1.4rem !default; // formerly xs
$font__size--400: 1.6rem !default; // formerly s
$font__size--500: 1.8rem !default; // formerly m
$font__size--600: 2.0rem !default; // formerly l
$font__size--700: 2.2rem !default; // formerly xl
$font__size--800: 2.4rem !default; // formerly xxl
$font__size--900: 2.8rem !default;  // formerly xxxl // in question
$font__size--1000: 3.6rem !default;  // formerly xxxxl // in question
$font__size--1100: 5.4rem !default; // formerly xxxxxl

// setting this as !default so that if there needs to be more specific adjustments beyond $font__line-height--increase for languages it can be done at the individual size level
$font__line-height--400: 1.2 * $font__line-height--increase !default; // formerly s
$font__line-height--500: 1.4 * $font__line-height--increase !default; // formerly m
$font__line-height--600: 1.6 * $font__line-height--increase !default; // formerly l


// according to https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight, the weights are as follows

// light = 300
// regular/normal = 400
// semibold = 600
// bold = 700
// extrabold = 800

$cdn--lane:'edge' !default;

// slab
$helam--folder: 20141031 !default;
@mixin fonts--helam($font--encoding:""){
    @font-face { font-family: "Helam"; font-weight: 300; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/helam/#{$helam--folder}/Helam-Slab-GWeb#{$font--encoding}-Light.woff") format("woff"); }
    @font-face { font-family: "Helam"; font-weight: 400; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/helam/#{$helam--folder}/Helam-Slab-GWeb#{$font--encoding}-Regular.woff") format("woff"); }
    @font-face { font-family: "Helam"; font-weight: 400; font-style: italic; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/helam/#{$helam--folder}/Helam-Slab-GWeb#{$font--encoding}-Italic.woff") format("woff"); }
    @font-face { font-family: "Helam"; font-weight: 700; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/helam/#{$helam--folder}/Helam-Slab-GWeb#{$font--encoding}-Bold.woff") format("woff"); }
    @font-face { font-family: "Helam"; font-weight: 700; font-style: italic; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/helam/#{$helam--folder}/Helam-Slab-GWeb#{$font--encoding}-Bold-Italic.woff") format("woff"); }
    @font-face { font-family: "Helam"; font-weight: 800; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/helam/#{$helam--folder}/Helam-Slab-GWeb#{$font--encoding}-Extra-Bold.woff") format("woff"); }
}


// sans
$zoram--folder: 20150327 !default;
@mixin fonts--zoram($font--encoding:""){
    // using open sans first if it is installed locally. Fall back to zoram if it doesn't exist or if specific characters are not supported.
    @font-face { font-family: "Zoram"; font-weight: 300; font-style: normal; src: local("Open Sans Light"), local("OpenSans-Light"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-Light.woff") format("woff"); }
    @font-face { font-family: "Zoram"; font-weight: 300; font-style: italic; src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-Light-Italic.woff") format("woff"); }
    @font-face { font-family: "Zoram"; font-weight: 400; font-style: normal; src: local("Open Sans"), local("OpenSans"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-Regular.woff") format("woff"); }
    @font-face { font-family: "Zoram"; font-weight: 400; font-style: italic; src: local("Open Sans Italic"), local("OpenSans-Italic"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-Italic.woff") format("woff"); }
    // was told we won't be using semi bold. commenting out for now
    // @font-face { font-family: "Zoram"; font-weight: 600; font-style: normal; src: local("Open Sans Semi Bold"), local("OpenSans-SemiBold"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-Semibold.woff") format("woff"); }
    // @font-face { font-family: "Zoram"; font-weight: 600; font-style: italic; src: local("Open Sans Semi Bold Italic"), local("OpenSans-BoldItalic"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-SemiboldItalic.woff") format("woff"); }
    @font-face { font-family: "Zoram"; font-weight: 700; font-style: normal; src: local("Open Sans Bold"), local("OpenSans-Bold"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-Bold.woff") format("woff"); }
    @font-face { font-family: "Zoram"; font-weight: 700; font-style: italic; src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-Bold-Italic.woff") format("woff"); }
    @font-face { font-family: "Zoram"; font-weight: 800; font-style: normal; src: local("Open Sans Extra Bold"), local("OpenSans-ExtraBold"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-ExtraBold.woff") format("woff"); }
    @font-face { font-family: "Zoram"; font-weight: 800; font-style: italic; src: local("Open Sans Extra Bold Italic"), local("OpenSans-ExtraBoldItalic"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/zoram/#{$zoram--folder}/Zoram-GWeb#{$font--encoding}-ExtraBoldItalic.woff") format("woff"); }
}
// serif
$pahoran--folder: 20141031 !default;
@mixin fonts--pahoran($font--encoding:""){
    @font-face { font-family: "Pahoran"; font-weight: 400; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/pahoran/#{$pahoran--folder}/Pahoran-GWeb#{$font--encoding}-Roman.woff") format("woff"); }
    @font-face { font-family: "Pahoran"; font-weight: 400; font-style: italic; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/pahoran/#{$pahoran--folder}/Pahoran-GWeb#{$font--encoding}-Italic.woff") format("woff"); }
    @font-face { font-family: "Pahoran"; font-weight: 700; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/pahoran/#{$pahoran--folder}/Pahoran-GWeb#{$font--encoding}-Bold.woff") format("woff"); }
    @font-face { font-family: "Pahoran"; font-weight: 700; font-style: italic; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/pahoran/#{$pahoran--folder}/Pahoran-GWeb#{$font--encoding}-Bold-Italic.woff") format("woff"); }
}

// icon font
$gideon--folder: 20190308 !default;
@mixin fonts--gideon($font--encoding:""){
    @font-face { font-family: "Gideon"; font-weight: 400; font-style: normal;
        src: url('//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/gideon/#{$gideon--folder}/gideon.eot');
        src: url('//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/gideon/#{$gideon--folder}/gideon.eot?#iefix') format('embedded-opentype'),
         url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/gideon/#{$gideon--folder}/gideon.woff") format("woff");
    // ,url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/gideon/#{$gideon--folder}/gideon.woff2") format("woff2");
    }
}

@mixin fonts--all($font--encoding:""){
    @include fonts--helam($font--encoding);
    @include fonts--zoram($font--encoding);
    @include fonts--pahoran($font--encoding);
    @include fonts--gideon($font--encoding);
    @if $font-injection == Scheherazade {
        @include fonts--scheherazade();
    }
    @if $font-injection == NotoSansMyanmar {
        @include fonts--NotoSansMyanmar();
    }
}

/*
International fonts
*/

@mixin fonts--scheherazade(){
    @import url(https://fonts.googleapis.com/css?family=Scheherazade:400,700&subset=arabic);
}
@mixin fonts--NotoSansMyanmar($font--encoding:""){
    @font-face { font-family: "NotoSansMyanmar"; font-weight: 400; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/NotoSansMyanmar/NotoSansMyanmar-Regular.woff2") format("woff2"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/NotoSansMyanmar/NotoSansMyanmar-Regular.woff") format("woff"); }
    @font-face { font-family: "NotoSansMyanmar"; font-weight: 700; font-style: normal; src: url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/NotoSansMyanmar/NotoSansMyanmar-Bold.woff2") format("woff2"), url("//#{$cdn--lane}.ldscdn.org/cdn2/common/fonts/NotoSansMyanmar/NotoSansMyanmar-Bold.woff") format("woff"); }
}
