@use "sass:math";

$className: spark-link-list;

.lumen-title-block .#{$className} { /* Necessary to overwrite .lumen-title-block color */
    color: $color__black--800;
}

.#{$className} {
    &__heading {
        @extend h4;
    }

    &__items[class] {
        margin: $spacing--300 0 0;
        display: block;
        column-gap: lumen-gutter(300);

        @media (min-width: $breakpoint--400) {
            column-gap: lumen-gutter(400);
        }
        @media (min-width: $breakpoint--500) {
            column-gap: lumen-gutter(500);
        }
        @media (min-width: $breakpoint--600) {
            column-gap: lumen-gutter(600);
        }
        @media (min-width: $breakpoint--700) {
            column-gap: lumen-gutter(700);
        }
        &:before,
        &:after {
            // this adds extra children to fill in gaps for 3 and 4 column layouts.
            // Make sure to set the sizes for these when setting the size for other child elements
            content:"";
            order:999;
            height:0;
            //Fix for IE11 on windows 7.
            display: block;
        }

        & .#{$className}__items__item {
            -moz-column-break-inside: avoid;
            -webkit-column-break-inside: avoid;
            column-break-inside: avoid;
            max-width: 100%;

            &:last-child {
                a.lumen-button {
                    margin-bottom: $spacing--400;
                }
            }

            a {
                display: table;

                &.lumen-button {
                    margin-right: 0;
                }
                &:not(.lumen-button){
                    margin-bottom: $spacing--400;
                }
            }
        }
    }

    &--columns {
        &-1 .#{$className}__items {
            columns: 1
        }

        &-2 .#{$className}__items {
            columns: 1;
            @media (min-width: $breakpoint--400) {
                columns: 2;
            }
        }
        &-3 .#{$className}__items {
            columns: 1;
            @media (min-width: $breakpoint--400) {
                columns: 2;
            }
            @media (min-width: $breakpoint--600) {
                columns: 3;
            }
        }
        &-4 .#{$className}__items {
            columns: 1;
            @media (min-width: $breakpoint--400) {
                columns: 2;
            }
            @media (min-width: $breakpoint--600) {
                columns: 4;
            }
        }
    }

    &--flow {
        .#{$className}__items {
            &[class] {
                display: flex;
                flex-wrap: wrap;
                columns: 1;
            }

            &__item[class] {
                display: inline-block;
                line-height: 1;
                margin: 0;
                padding: 0;
            }

            a {
                display: inline-block;

                &.lumen-button[class] {
                    margin: 0 $spacing--200 $spacing--200 0;
                }
                &:not(.lumen-button) {
                    margin-right: $spacing--400;
                }
            }
        }
    }

    &--row {
        .#{$className}__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: left;
            flex: 1 1;

            &__item[class] {
                width: 100%;
            }
        }

        &.#{$className}--columns {
            &-2 .#{$className}__items__item[class] {

                @media (min-width: $breakpoint--400) {
                    width: calc(100% / 2 - 1px);

                    &:nth-child(2n-1){
                        padding-right: math.div(lumen-gutter(400), 2);
                    }
                    &:nth-child(2n){
                        padding-left: math.div(lumen-gutter(400), 2);
                    }
                }
                @media (min-width: $breakpoint--500) {
                    &:nth-child(2n-1){
                        padding-right: math.div(lumen-gutter(500), 2);
                    }
                    &:nth-child(2n){
                        padding-left: math.div(lumen-gutter(500), 2);
                    }
                }
                @media (min-width: $breakpoint--600) {
                    &:nth-child(2n-1){
                        padding-right: math.div(lumen-gutter(600), 2);
                    }
                    &:nth-child(2n){
                        padding-left: math.div(lumen-gutter(600), 2);
                    }
                }
                @media (min-width: $breakpoint--700) {
                    &:nth-child(2n-1){
                        padding-right: math.div(lumen-gutter(700), 2);
                    }
                    &:nth-child(2n){
                        padding-left: math.div(lumen-gutter(700), 2);
                    }
                }
            }
            &-3 .#{$className}__items__item[class] {

                @media (max-width: #{$breakpoint--400 - 1}) {
                    margin-right: 0;
                }

                @media (min-width: $breakpoint--400) {
                    width: calc(100% / 2 - 1px);

                    @media (max-width: #{$breakpoint--500 - 1}) {
                        &:nth-child(2n-1){
                            padding-right: math.div(lumen-gutter(400), 2);
                        }
                        &:nth-child(2n){
                            padding-left: math.div(lumen-gutter(400), 2);
                        }
                    }
                }

                @media (min-width: $breakpoint--500) {
                    width: calc(100% / 3 - 1px);
                    padding-right: math.div(lumen-gutter(500), 2);

                    &:nth-child(3n-1){
                        padding-left: math.div(lumen-gutter(500), 2);
                    }
                    &:nth-child(3n){
                        padding-left: math.div(lumen-gutter(500), 2);
                        padding-right: 0;
                    }
                }

                @media (min-width: $breakpoint--600) {
                    padding-right: math.div(lumen-gutter(600), 2);
                    &:nth-child(3n-1){
                        padding-left: math.div(lumen-gutter(600), 2);
                    }
                    &:nth-child(3n){
                        padding-left: math.div(lumen-gutter(600), 2);
                        padding-right: 0;
                    }
                }
                @media (min-width: $breakpoint--700) {
                    padding-right: math.div(lumen-gutter(700), 2);
                    &:nth-child(3n-1){
                        padding-left: math.div(lumen-gutter(700), 2);
                    }
                    &:nth-child(3n){
                        padding-left: math.div(lumen-gutter(700), 2);
                        padding-right: 0;
                    }
                }

            }
            &-4 .#{$className}__items__item[class] {

                @media (min-width: $breakpoint--400) {
                    width: calc(100% / 2 - 1px);
                    @media (max-width: #{$breakpoint--500 - 1}) {
                        &:nth-child(2n-1){
                            padding-right: math.div(lumen-gutter(400), 2);
                        }
                        &:nth-child(2n){
                            padding-left: math.div(lumen-gutter(400), 2);
                        }
                    }
                }
                @media (min-width: $breakpoint--500){
                    @media (max-width: #{$breakpoint--600 - 1}) {
                        &:nth-child(2n-1){
                            padding-right: math.div(lumen-gutter(500), 2);
                        }
                        &:nth-child(2n){
                            padding-left: math.div(lumen-gutter(500), 2);
                        }
                    }
                }
                @media (min-width: $breakpoint--600) {
                    width: calc(100% / 4 - 1px);
                    padding-left: math.div(lumen-gutter(600), 2);
                    padding-right: math.div(lumen-gutter(600), 2);
                    &:nth-child(4n-3){
                        padding-left: 0;
                    }
                    &:nth-child(4n){
                        padding-right: 0;
                    }

                }

                @media (min-width: $breakpoint--700) {
                    padding-left: math.div(lumen-gutter(700), 2);
                    padding-right: math.div(lumen-gutter(700), 2);
                    &:nth-child(4n-3){
                        padding-left: 0;
                    }
                    &:nth-child(4n){
                        padding-right: 0;
                    }
                }
            }
        }
    }

    @import '../mjs-hide-items/mjs-hide-items.scss';
}
