.lumen-content-block {
    // this is for wysiwyg throwing in divs instead of ps sometimes.
    > div {
        margin:0 0 $spacing--400;
    }
}
@mixin lumen-content-block--full() {
    > .lumen-content-block {
        width: lumen-columns(4,300);
        margin-#{$dir-start}: 0;

        @media (min-width: $breakpoint--400){
            width: lumen-columns(10,400);
            margin-#{$dir-start}: lumen-columns(1,400, true);
        }

        @media (min-width: $breakpoint--500){
            width: lumen-columns(8,500);
            margin-#{$dir-start}: lumen-columns(2,500, true);
        }

        @media (min-width: $breakpoint--600){
            width: lumen-columns(6,600);
            margin-#{$dir-start}: lumen-columns(3,600, true);
        }

        @media (min-width: $breakpoint--700){
            width: lumen-columns(6,700);
            margin-#{$dir-start}: lumen-columns(3,700, true);
        }
        @media print {
            width: 94%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
