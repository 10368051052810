.spark-image-gallery {
    margin: $spacing--600 0;
    text-align: center;

    .lumen-frame--narrow & {
        @include breakout-both();
    }

    &__items {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        //make the container larger to account for spacing on right side of images,
        //so that images align with the grid.
        margin-right: -$spacing--200 - 1; //-1 for rounding errors
    }

    & &__load-more.lumen-button[class] {
        font-size: $font__size--200;
        display: none;
    }

    .spark-image-gallery-item {
        margin: 0 $spacing--200 $spacing--200 0;
    }

    &[data-aspect-ratios] .spark-image-gallery-item {
        display: none;
    }
}
