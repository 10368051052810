$dir-start: left !default;
$dir-end: right !default;
@mixin lumen-frame--full() {
    // setup the general widths and margins.
    width: lumen-columns(4,300);
    margin-#{$dir-start}: lumen-gutter(300,true);

    @media (min-width: $breakpoint--400){
        width: lumen-columns(12,400);
        margin-#{$dir-start}: lumen-gutter(400,true);
    }

    @media (min-width: $breakpoint--500){
        width: lumen-columns(12,500);
        margin-#{$dir-start}: lumen-gutter(500,true);
    }

    @media (min-width: $breakpoint--600){
        width: lumen-columns(12,600);
        margin-#{$dir-start}: lumen-gutter(600,true);
    }

    @media (min-width: $breakpoint--700){
        width: lumen-columns(12,700);
        margin-#{$dir-start}: lumen-columns(2,700, true) + lumen-gutter(700,true);
    }
}
