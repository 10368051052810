.spark-feature {
    position: relative;
    color: $color__white;
    overflow: hidden;
    text-align: center;
    margin: $spacing--500 0;

    @media (max-width: $breakpoint--400){
        color: $color__black--800;
    }

    .lumen-image img{
        min-width: 100%;
    }

    &__text[class] {
        width: 100%;
        bottom: 0;
        padding-top: $spacing--700;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;

        position: absolute;
        left:0;
        padding-bottom: $spacing--600;

        background: $color__black--translucent--dark;
        background: transparent linear-gradient(transparent, $color__black--translucent--dark);

        > a {
            color: $color__white;
            &:hover {
                color: $color__white;
                text-decoration: none;
            }
        }

        @media (max-width: $breakpoint--400){
            position: static;
            background: transparent;
            padding-top: $spacing--600;

            > a {
                color: $color__black--800;
                &:hover {
                    color: $color__black--800;
                    text-decoration: none;
                }
            }
        }
        @media (min-width: $breakpoint--400){
            padding-left: lumen-columns(1,400,true);
            padding-right: lumen-columns(1,400,true);
        }
        @media (min-width: $breakpoint--500){
            padding-left: lumen-columns(1,500,true);
            padding-right: lumen-columns(1,500,true);
        }
        @media (min-width: $breakpoint--600){
            padding-left: lumen-columns(2,600,true);
            padding-right: lumen-columns(2,600,true);
        }
        @media (min-width: $breakpoint--700){
            padding-left: lumen-columns(2,700,true);
            padding-right: lumen-columns(2,700,true);
        }
    }

    .lumen-icon {
        @media (max-width: $breakpoint--400){
            display: none;
        }
        color: $color__white;

        //can the size be relative to the size of the posterframe?
        font-size: 50px;
        z-index: 200;
        line-height: 1em;


        &__graphic:before {
            background: $color__black--translucent;
            box-sizing: content-box;
            width: 1em;
            text-align: center;
            border: 0.08em solid rgba($color__black--translucent, 0.0);
        }
        @media screen and (min-width: $breakpoint--400){
            padding-bottom: $spacing--600;
            font-size: 74px;
        }
    }


    &__pre-title {
        text-transform: uppercase;
        font-size: $font__size--300;
        font-weight: 600;
        position: relative;
        letter-spacing: 0.5px;
        margin-bottom: $spacing--50;

        @media screen and (min-width: $breakpoint--400){
            font-size: $font__size--400;
        }
    }

    &__title {
        @extend h1;
        margin-bottom: $spacing--300;
    }

    &__content {
        margin-bottom: $spacing--500;
        font-family: $font__family--sans;
        font-weight: 300;
    }

    &--fit-small {
        background: $color__black--800;
        .lumen-image {
            opacity: 0.75;
        }
        .spark-feature {
            &__text {
                padding-top: $spacing--500;
                padding-bottom: $spacing--500;
            }
            &__play-button .lumen-icon{
                font-size: 60px;
                padding-bottom: $spacing--300;
            }
            &__pre-title {
                font-size: $font__size--300;
            }
            &__title[class] {
                font-size: $font__size--1000;
                margin-bottom: $spacing--200;
            }
            &__content {
                font-size: $font__size--600;
                margin-bottom: $spacing--400;
            }
        }
    }
    &--fit-smaller {
        background: $color__black--800;
        .lumen-image{
            opacity: 0.7;
        }
        .spark-feature {
            &__text {
                padding-top: $spacing--400;
                padding-bottom: $spacing--400;
            }
            &__play-button .lumen-icon{
                font-size: 50px;
                padding-bottom: $spacing--200;
            }
            &__pre-title {
                font-size: $font__size--200;
            }
            &__title {
                font-size: $font__size--900;
                margin-bottom: $spacing--100;
            }
            &__content {
                font-size: $font__size--500;
                margin-bottom: $spacing--300;
            }
        }
    }
    &--fit-smallest {
        background: $color__black--800;
        .lumen-image {
            opacity: 0.6;
        }
        .spark-feature {
            &__text {
                padding-top: $spacing--300;
                padding-bottom: $spacing--300;
            }
            &__play-button {
                display: none;
            }
            // &__play-button .lumen-icon{
            //     font-size: 50px;
            //     padding-bottom: $spacing--200;
            // }
            &__pre-title {
                font-size: $font__size--200;
            }
            &__title {
                font-size: $font__size--800;
                margin-bottom: $spacing--100;
            }
            &__content {
                display: none;
            }
        }
    }

    &--text-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;


        &.spark-feature {
            text-align: center;
            .lumen-image__wrapper {
                overflow: hidden;
                border-radius: 50%;
                width: lumen-columns(2,300);

                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(2,400,true);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(2,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(2,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(2,700);
                }

                .lumen-image {
                    margin-bottom: 0;
                }

                .lumen-icon {
                    display: none;
                }
            }
        }

        .spark-feature {
            &__play-button {
                display: none;
            }
            &__text {
                position: static;
                background: transparent;
                display: block;
                padding: 0;


                > a {
                    color: $color__black--800;
                    &:hover {
                        color: $color__black--800;
                        text-decoration: none;
                    }
                }
            }

            &__pre-title {
                font-size: $font__size--200;
                margin-bottom: $spacing--25;
            }
        }
    }

    &--text-right {

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: $color__black--800;
        text-align: left;

        @media (max-width: $breakpoint--500){
            flex-direction: column;
        }

        .spark-feature {
            &__text {
                position: static;
                background: transparent;
                display: block;
                padding: $spacing--300 0;


                > a {
                    color: $color__black--800;
                    &:hover {
                        color: $color__black--800;
                        text-decoration: none;
                    }
                }

                @media (min-width: $breakpoint--500){
                    padding: $spacing--300 0;
                    width: lumen-columns(4,500);
                    border-top: 1px solid $color__black--400;
                }
                @media (min-width: $breakpoint--600){
                    padding: $spacing--300 0;
                    width: lumen-columns(4,600);
                }
                @media (min-width: $breakpoint--700){
                    padding: $spacing--300 0;
                    width: lumen-columns(4,700);
                }
            }

            &__pre-title {
                font-size: $font__size--200;
                margin-bottom: $spacing--25;
            }

            &__title[class] {
                text-align: left;
                font-size: $font__size--900;

                @media screen and (min-width: $breakpoint--300){
                    font-size: $font__size--1000;
                }
            }

            &__play-button {
                display: none;
            }
        }

        .lumen-image {
            width: 100%;
            @media (min-width: $breakpoint--500){
                width: lumen-columns(8,500);
            }
            @media (min-width: $breakpoint--600){
                width: lumen-columns(8,600);
            }
            @media (min-width: $breakpoint--700){
                width: lumen-columns(8,700);
            }
        }

        &--fit-small {
            @media (min-width: $breakpoint--500){
                .spark-feature {
                    &__title[class] {
                        font-size: $font__size--900;
                        margin-bottom: $spacing--200;
                    }
                    &__content {
                        font-size: $font__size--400;
                        margin-bottom: $spacing--400;
                    }
                }
            }
        }
        &--fit-smaller {
            @media (min-width: $breakpoint--500){
                .spark-feature {
                    &__title[class] {
                        margin-bottom: $spacing--200;
                    }
                    &__content {
                        display: none;
                    }
                }
            }
        }
        &--fit-smallest {
            @media (min-width: $breakpoint--500){
                .spark-feature {
                    &__title[class] {
                        font-size: $font__size--900;
                    }
                    &__content {
                        display: none;
                    }
                }
            }
        }

    }
}
@include lumen-form--buttons();
