$dir-start: left !default;

@mixin lumen-frame--split() {
    width: lumen-columns(4,300);
    margin-#{$dir-start}: lumen-gutter(300,true);

    @media (min-width: $breakpoint--400){
        display: flex;
        justify-content: space-between;

        @if $dir-start == right{
            flex-direction:row-reverse;
        }
        width: lumen-columns(12,400);
        margin-#{$dir-start}: lumen-gutter(400,true);
    }

    @media (min-width: $breakpoint--500){
        width: lumen-columns(12,500);
        margin-#{$dir-start}: lumen-gutter(500,true);
    }

    @media (min-width: $breakpoint--600){
        width: lumen-columns(12,600);
        margin-#{$dir-start}: lumen-gutter(600,true);
    }

    @media (min-width: $breakpoint--700){
        width: lumen-columns(12,700);
        margin-#{$dir-start}: lumen-columns(2,700, true) + lumen-gutter(700,true);
    }


    &--major {
        width: lumen-columns(4,300);
        margin-bottom: $spacing--300;

        @media (min-width: $breakpoint--400){
            width: lumen-columns(8,400);
            margin-bottom: 0;
        }

        @media (min-width: $breakpoint--500){
            width: lumen-columns(8,500);
        }

        @media (min-width: $breakpoint--600){
            width: lumen-columns(8,600);
        }

        @media (min-width: $breakpoint--700){
            width: lumen-columns(8,700);
        }
    }
    &--minor {
        width: lumen-columns(4,300);

        @media (min-width: $breakpoint--400){
            width: lumen-columns(4,400);

        }

        @media (min-width: $breakpoint--500){
            width: lumen-columns(4,500);
        }

        @media (min-width: $breakpoint--600){
            width: lumen-columns(4,600);
        }

        @media (min-width: $breakpoint--700){
            width: lumen-columns(4,700);
        }
    }
}
