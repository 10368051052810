.spark-order-form-module {
    input[type="checkbox"] {
        float: left;
    }

    .lumen-tile {
        border: 0;
        float: right;
        width: calc(100% - #{$spacing--400});
    }

    &__quantity {
        clear: both;
        position: relative;
        text-align: center;

        input {
            width: 60px;
        }
    }

    &__qbtn {
        &.lumen-button[class] {
            position: absolute;
            bottom: 0;
            font-size: $font__size--800;
            line-height: 0;
            margin: 0;
            height: 36px;
            width: 36px;
        }

        &--fewer {
            left: calc(50% - 72px); //36 + 60px/2 + 6
        }

        &--greater {
            left: calc(50% + 36px); //60px/2 + 6
        }
    }
}
