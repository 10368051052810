.lumen-region {
    &.lumen-frame--narrow {
        @include lumen-frame--narrow();
        @include lumen-title-block--narrow();
        @include lumen-quote--narrow();
    }

    &.lumen-frame--full {
        @include lumen-frame--full();
        @include lumen-content-block--full();
        @include lumen-media-block--full();
    }

    /* split frame support would go here. */
}
