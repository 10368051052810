.lumen-quote {
  // extra [class] is to match specificity of '.lumen-content blockquote'
  &[class] {
    border-left: 0; // compensate for blockquote styling
    padding-left: 0; // compensate for blockquote styling
    margin-left: auto; // compensate for blockquote styling
    margin: $spacing--600 auto;
    position: relative;
    text-align: center;

    @media (min-width: $breakpoint--400) {
      margin: $spacing--700 auto;
    }
    @media (min-width: $breakpoint--500) {
      width: lumen-columns(10, 500);
    }
    @media (min-width: $breakpoint--600) {
      margin: $spacing--800 auto;
      width: lumen-columns(8, 600);
    }
    @media (min-width: $breakpoint--700) {
      width: lumen-columns(8, 700);
    }
  }

  &__quote[class] {
    font-size: $font__size--900;
    font-family: $font__family--serif;
    font-style: italic;
    font-weight: 400;
    line-height: $font__line-height--400;
    margin-bottom: 0;
    @media (min-width: $breakpoint--400) {
      font-size: $font__size--1000;
    }
  }

  &__citation {
    font-size: $font__size--200;
    font-style: normal;
    display: inline-block;
    margin-top: $spacing--400;
    font-family: $font__family--serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 400;
    color: $color__black--800;
    @media (min-width: $breakpoint--400) {
      font-size: $font__size--300;
    }
  }

  //hidden on full frame layouts
  &__social,
  hr {
    display: none;
  }

  &--grey {
    color: $color__black--700;
  }
  &--green {
    color: $color__green--500;
  }
  &--blue {
    color: $color__blue--500;
  }
  &--red {
    color: $color__red--500;
  }
  &--orange {
    color: $color__orange--500;
  }
}
@mixin lumen-quote--narrow() {
  .lumen-quote {
    // extra [class] is to match specificity of '.lumen-content blockquote'
    &[class] {
      margin: $spacing--700 auto;

      @media (min-width: $breakpoint--500) {
        width: auto;
      }
      @media (min-width: $breakpoint--600) {
        margin: $spacing--800 auto;
        width: auto;
      }
      @media (min-width: $breakpoint--700) {
        width: auto;
      }
    }

    &__social,
    hr {
      display: block;
    }

    &__social {
      margin-top: $spacing--500;

      @media (min-width: $breakpoint--400) {
        display: flex;
        align-items: flex-end;
        flex-flow: column wrap;
        justify-content: center;
        height: 100%;
        position: absolute;
        margin-left: -1 * lumen-columns(1, 400, true);
        margin-top: 0;
        text-align: right;
        top: 0;
        width: lumen-columns(1, 400);
      }

      @media (min-width: $breakpoint--500) {
        margin-left: -1 * lumen-columns(1, 500, true);
        width: lumen-columns(1, 500);
      }

      @media (min-width: $breakpoint--600) {
        margin-left: -1 * lumen-columns(1, 600, true);
        width: lumen-columns(1, 600);
      }

      @media (min-width: $breakpoint--700) {
        margin-left: -1 * lumen-columns(1, 700, true);
        width: lumen-columns(1, 700);
      }
    }

    hr:first-of-type {
      margin-bottom: $spacing--500;

      @media (min-width: $breakpoint--400) {
        margin-bottom: $spacing--600;
      }
    }
    hr:last-of-type {
      margin-top: $spacing--300;

      @media (min-width: $breakpoint--400) {
        margin-top: $spacing--600;
      }
    }

    &__twitter {
      font-size: $font__size--800;

      &:hover {
        .lumen-icon__graphic--twitter:before {
          color: lighten($color__twitter, 10%);
        }
      }
    }
  }
}
