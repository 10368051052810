// MISC STUFF that doesn't have enough for their own partial
$border--m: 2px;

$border__radius:3px;
$box__shadow: 0 0 4px $color__black--translucent;

//Transition Speed
$speed--300: 500ms;
$speed--400: 300ms;
$speed--500: 100ms;

//Layering

//in item layering
$layer--100: 100;
$layer--200: 200;

//Cross item layering
$layer--300: 300;
$layer--400: 400;

//page level layering
$layer--500: 500;
$layer--600: 600;
$layer--700: 700;

//Application level layering
$layer--800: 800;
$layer--900: 900;

//topmost layer. Use this sparingly.
$layer--1000: 1000;

// going to experiment with EQCSS (http://elementqueries.com/) as a progressive enhancement. Setting a variable that we can use in experimenting with it.
$hasEQCSS: null;