.spark-pagination {
    width: auto;
    display: inline-block;
    margin: $spacing--600 0 $spacing--800;

    &__list {
        display: flex;
        padding-left: 0;
        width: auto;
    }

    &__list-item {
        list-style: none;
        flex: 0 1 auto;


        &__item {
            &[class]{
                color: $color__black--400;
                display: block;
                padding: $spacing--50 $spacing--200;
                padding: $spacing--25 $spacing--100;
                font-size: $font__size--300;
                font-weight: 400;
                color: $color__black--600;
                border-radius: $border__radius;
                margin-right: $spacing--100;

                &:hover, &:focus {
                    box-shadow: 0 0 0 1px $color__black--300 inset;
                    color: $color__black--600;
                    text-decoration: none;
                    outline: none;
                    font-weight: 600;
                }
            }

            &--active[class] {
                background: $color__blue--600;
                cursor: default;
                color: $color__white;
                font-weight: 600;

                &:hover, &:focus {
                    box-shadow: none;
                    color: $color__white;
                }
            }
        }

        &--previous,
        &--next {
            .spark-pagination__list-item__item {
                color: $color__blue--600;
                font-size: $font__size--700;
                line-height: 1;
                padding: 2px 3px 3px;

                &:hover {
                    color: $color__blue--700;
                }
            }
        }
    }
}
