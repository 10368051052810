.lumen-sub-nav {
    background: $color__white;
    box-shadow: $box__shadow;
    font-size: $font__size--200;
    padding: $spacing--300 0 0;
    position: relative;
    width: 100%;

    //align to left within full frame
    @media (min-width: $breakpoint--400) {
        align-items: center;
        display: flex;
        padding-top: $spacing--25;
    }

    @media (max-width: $breakpoint--400 - 1) {
        &::before {
            background: linear-gradient(to right, #{rgba($color__black--300,0)}, $color__black--300);
            content: '';
            display: block;
            height: 100%;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
        }
    }

    ul {
        align-self: flex-start;
        list-style: none;
        margin: 0;
        padding: 0 0 20px;

        li,
        li:first-child {
            margin: 0;
            padding: $spacing--100 $spacing--300;
        }
    }

    a {
        color: $color__black--800;
        display: block;
        text-decoration: none;
        width: 100%;

        &:hover {
            color: $color__black--800;
            text-decoration: none;
        }
    }

    &__header[class] {
        font: 700 $font__size--200#{'/'}$font__line-height--400 $font__family--sans;
        letter-spacing: 0.05em;
        margin: 0 $spacing--600 0 0;
        padding: 0 $spacing--200 0 31px; //matches l&r padding on platform
        text-transform: uppercase;

        @media (max-width: 799px) {
            padding: 0 $spacing--200;
        }
        @media (min-width: $breakpoint--400) {
            flex: 0 0 auto;
            max-width: 40vw;
            margin: 0;
            padding-bottom: $spacing--25;
        }
    }

    &__wrapper {
        align-items: center;
        display: flex;
        flex: 1 1 100%;
        height: 48px;
        overflow: hidden;
        justify-content: flex-start;

        @media (min-width: $breakpoint--400) {
            justify-content: flex-end;
        }
    }

    &__advance, &__retreat {
        cursor: pointer;
        display: none;
        font-size: $font__size--500;
        padding: 2px $spacing--200;
        position: relative;

        @media (min-width: $breakpoint--400) {
            &.active {
                display: block;
            }
        }

        &::before {
            content: '';
            width: 80px;
            height: 100%;
            background: linear-gradient(to right, #{rgba($color__white,0)}, #{rgba($color__white,1)});
            display: block;
            position: absolute;
            left: -81px;
            top: 0;
            pointer-events: none;
        }
    }

    &__advance {
        border-left: 1px solid $color__black--500;
    }
    &__retreat {
        border-right: 1px solid $color__black--500;

        &::before {
            background: linear-gradient(to left, #{rgba($color__white,0)}, #{rgba($color__white,1)});
            left: auto;
            right: -81px;
        }
    }

    &__list {
        display: flex;
        overflow-x: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        &[class] > li:first-child {
            padding-left: 0;
            margin-left: 31px;

            @media (max-width: 799px) {
                margin-left: $spacing--200;
            }
        }

        li {
            > a,
            > span {
                border-bottom: 3px solid transparent;
                cursor: pointer;
                display: inline-block;
                padding-bottom: 1px;
                white-space: nowrap;

                &:hover {
                    border-bottom-color: $color__blue--600;
                }
            }

            &.active,
            &.open {
                > a,
                > span {
                    border-bottom-color: $color__blue--600;
                }
            }

            > span:after {
                @include icon--pseudo-style();
                content: '#{map-get($icons, down-open)}';
            }

        }

        > li {
            > a,
            > span {
                padding: $spacing--50 0 $spacing--300;
            }
        }
    }

    &__sub-list {
        &[class] {
            background: $color__white;
            box-shadow: 0 4px 4px 0 $color__black--translucent; //don't want drop shadow on top of sub-list, i.e. purposefully not using $box__shadow.
            display: none;
            left: 0;
            min-width: 100%;
            padding: $spacing--100 0;
            position: absolute;
            z-index: $layer--100;

            @media (min-width: $breakpoint--400){
                max-width: lumen-columns(8,400);
                min-width: auto;
                padding-right: $spacing--300;
            }
            @media (min-width: $breakpoint--500) {
                max-width: lumen-columns(6,500);
            }
            @media (min-width: $breakpoint--600) {
                max-width: lumen-columns(5,600);
            }
            @media (min-width: $breakpoint--700) {
                max-width: lumen-columns(5,700);
            }

            &.active {
                display: block;
            }

            li {
                a {
                    border: 0;
                    display: inline;
                    line-height: $font__line-height--600;
                    white-space: normal;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &--2-columns[class] {
            @media (min-width: $breakpoint--400) {
                &.active {
                    display: flex;
                    flex-flow: row wrap;
                }

                li {
                    flex: 0 0 50%;
                }
            }
        }
    }
}
