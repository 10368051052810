.spark-date-select {
    color: $color__black--700;
    display: block;
    font-size: $font__size--200;
    margin-bottom: $spacing--600;

    &__label {
        display: block;
        margin-bottom: $spacing--50;
    }

    .spark-select {
        display: inline-block;
        margin: 0 $spacing--50;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
