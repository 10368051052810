@mixin icon--pseudo-style(){
    font-family: $icon__font__family;
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    height: 1em;
    max-width: 1em;
    border-radius: 1em;
    position: relative;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@mixin icon--css($prefix:"lumen-icon"){ // default to all icons if none are pased in
    $icon__gap--graphic-text: .2em !default;
    $icon__gap--icon-icon: 1em !default;
    $icon__font__family: "need to declare a font family" !default; // Since lumen-sass isn't required to use this component, we warn to set the value. If including lumen-sass, this will already be available.
    .#{$prefix} {
        // BLOCK
        display:inline-flex;

        // ELEMENTS
        &__graphic{
            &:before {
                @include icon--pseudo-style();
            }
        }

        &__text {
            position: absolute;
            #{$dir-start}: -1000000px;
        }
        // MODIFIERS
        &--prefix,
        &--suffix {
            .#{$prefix}__graphic {
                margin-#{$dir-end}: $icon__gap--graphic-text;
            }
            .#{$prefix}__text {
                position: static;
            }
        }

        &--suffix {
            flex-direction:row-reverse;
            .#{$prefix}__text {
                margin-#{$dir-end}: $icon__gap--graphic-text;
            }
        }

        &--above,
        &--below {
            text-align: center;
            .#{$prefix}__graphic {
                width: auto;
                display: inline;
            }
            .#{$prefix}__text {
                position: static;
            }
        }
        &--above {
            flex-direction: column;
        }
        &--below {
            flex-direction: column-reverse;
        }
    }
}
