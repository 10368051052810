.video-gallery-item {
    @include lumen-fullbleed-background(narrow);

    background: $color__black--800;
    color: $color__white;
    padding: $spacing--700 0;

    .spark-link-list__items__item a {
        color: $color__blue--400;
    }

    .lumen-media-block .spark-link-list__items__item .lumen-button--tag{
        color: $color__white;
        border: 1px solid $color__white;
    }

    .lumen-media-block .spark-link-list__items__item .lumen-button--tag:hover{
        color: $color__blue--400;
        border: 1px solid $color__blue--400;
    }

    .lumen-title-block .spark-link-list {
        color: $color__white;
    }

    .lumen-content-block {
        color: $color__white;
    }

    .lumen-title-block {
        margin: 0;
    }

    .lumen-icon__graphic:before {
        color: $color__black--200;
    }

    .lumen-icon__text {
        color: $color__black--200;
    }

    .lumen-content-block a {
        color: $color__blue--400;
    }

    span.lumen-media-block__caption__title {
        color: $color__black--200;
    }
}
