.pf-footer {

    .spark-link-list {
        &__items {
            margin: 0;
        }
    }

    .lumen-icon__graphic {
        font-size: $font__size--800;
    }

    .content-rating {
        display: inline-block;
        margin-#{$dir-start}: $spacing--50;
        padding: 0 2px;
        border-radius: $border__radius;
        border: 1px $color__black--800 solid;
        color: $color__black--800;
        text-align: center;
    }
}
