@use "sass:math";
// GRID
// The grid is still fairly untested in the "real world". We have used it on a very basic article template, and made some example "complex" layout templates. Thinking and functionality may change as we start using it on "real" complex layouts and see how it will actually work.

// gutter width
// the with of a gutter, which is the small gap on each side of a column. This usually (except for the left of the first col and right of the last col) docks up with the guttter for the next column making the actual gap be two gutters wide
// Doing * 2 for the moment to make sure it works as desired, may just double it to begin with but not critical as it has the same result
$grid__gutter-width--300: 2.8vw * 2;
$grid__gutter-width--400: 2vw * 2;
$grid__gutter-width--500: 1.6vw * 2;
$grid__gutter-width--600: 1.2vw * 2;
$grid__gutter-width--700: 0.8vw * 2;

// widths
// the width of the usable space by the grid, removing some margins from the sides to keep the content off of the edge. margin * 2 is actually gutter * 4. This is making the usable space to be two gutters in from each side.
$grid__width--300: 100 - ($grid__gutter-width--300 * 2);
$grid__width--400: 100 - ($grid__gutter-width--400 * 2);
$grid__width--500: 100 - ($grid__gutter-width--500 * 2);
$grid__width--600: 100 - ($grid__gutter-width--600 * 2);
$grid__width--700: 100 - ($grid__gutter-width--700 * 2);

// column-numbers
$grid__column-number--300: 4;
$grid__column-number--400: 12;
$grid__column-number--500: 12;
$grid__column-number--600: 12;
$grid__column-number--700: 16;

// column-widths
$grid__column-width--300: math.div($grid__width--300, $grid__column-number--300);
$grid__column-width--400: math.div($grid__width--400, $grid__column-number--400);
$grid__column-width--500: math.div($grid__width--500, $grid__column-number--500);
$grid__column-width--600: math.div($grid__width--600, $grid__column-number--600);
$grid__column-width--700: math.div($grid__width--700, $grid__column-number--700);


$grid__column-width: (
    '300': $grid__column-width--300,
    '400': $grid__column-width--400,
    '500': $grid__column-width--500,
    '600': $grid__column-width--600,
    '700': $grid__column-width--700
);

$grid__gutter-width: (
    '300': $grid__gutter-width--300,
    '400': $grid__gutter-width--400,
    '500': $grid__gutter-width--500,
    '600': $grid__gutter-width--600,
    '700': $grid__gutter-width--700
);

// FUNCTIONS
@function lumen-columns($numberOfColumns, $size, $extraGutter: false){ // extraGutter adds back in a gutter that we normally remove so that content doesn't go into the gutter space. Normally this will be for when this is used as a margin/padding.
    $columnWidth : map-get($grid__column-width, $size+"");
    $gutterWidth : map-get($grid__gutter-width, $size+"");

    @if $extraGutter {
        @return ($columnWidth * $numberOfColumns);
    }
    @else {
        @return ($columnWidth * $numberOfColumns) - $gutterWidth;
    }
}

@function lumen-gutter($size, $toEdge: false){
    $gutter-width : map-get($grid__gutter-width, $size+"");
    @if $toEdge {
        @return $gutter-width + math.div($gutter-width, 2);
    }
    @else {
        @return $gutter-width;
    }
}

@function lumen-fullbleed($numberOfColumns, $size){
    @return lumen-columns($numberOfColumns, $size,true) + lumen-gutter($size, true);
}

// MIXINS

// Allows you to visually break out of a frame (narrow or full),
// and have a background-color extend the full width of the screen,
// while maintaining the dimensions of the content.
@mixin lumen-fullbleed-background($frame) {
    margin-#{$dir-start}: lumen-gutter(300,true)*-1;
    padding-#{$dir-start}: lumen-gutter(300,true);
    padding-#{$dir-end}: lumen-gutter(300,true);
    width: 100vw;

    @if $frame == full {
        @media (min-width: $breakpoint--400){
            @include full-bleed-spacing(lumen-gutter(400,true));
        }

        @media (min-width: $breakpoint--500){
            @include full-bleed-spacing(lumen-gutter(500,true));
        }

        @media (min-width: $breakpoint--600){
            @include full-bleed-spacing(lumen-gutter(600,true));
        }

        @media (min-width: $breakpoint--700) {
            @include full-bleed-spacing(lumen-fullbleed(2,700));
        }
    }

    @if $frame == narrow {
        @media (min-width: $breakpoint--400){
            @include full-bleed-spacing(lumen-fullbleed(1,400));
        }

        @media (min-width: $breakpoint--500){
            @include full-bleed-spacing(lumen-fullbleed(2,500));
        }

        @media (min-width: $breakpoint--600){
            @include full-bleed-spacing(lumen-fullbleed(3,600));
        }

        @media (min-width: $breakpoint--700) {
            @include full-bleed-spacing(lumen-fullbleed(5,700));
        }
    }
}

@mixin full-bleed-spacing($width) {
    margin-#{$dir-start}: $width * -1;
    padding-#{$dir-start}: $width;
    padding-#{$dir-end}: $width;
}
