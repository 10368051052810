.spark-textarea {
    color: $color__black--700;
    display: block;
    font-size: $font__size--200;
    margin-bottom: $spacing--600;

    &__label {
        display: block;
        margin-bottom: $spacing--50;
    }

    &__text {
        border: 1px solid $color__black--500;
        border-radius: $border__radius;
        font-size: $font__size--400;
        min-height: 200px;
        padding: $spacing--50;
        width: 100%;
    }
}
