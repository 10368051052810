$dir-start: left !default;
$dir-end: right !default;

@mixin lumen-form--basic() {
    @include lumen-form--buttons();
    @include lumen-form--select();
}

@mixin lumen-form--select() {
  .lumen-content {
    select,
    .lumen-select {
      appearance: none;
      background-color: $color__white;
      border: 1px solid $color__black--800;
      border-radius: $border__radius;
      background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='640' height='640' viewBox='0 0 640 640'><style>/* <![CDATA[ */ path { fill: #{$color__black--800t}; } /* ]]> */ </style><path d='M553.7,155.92c18.42-17.25,46.08-17.25,63.33,0a43.77,43.77,0,0,1,0,62.2l-266,266c-17.25,17.25-44.91,17.25-63.28,0l-264.84-266a44,44,0,1,1,62.16-62.2L320,389.63Z'/></svg>") no-repeat right 17px center;
      background-size: 10px 10px;
      font-size: $font__size--400;
      height: 35px;
      line-height: $font__line-height--400;
      max-width: 300px;
      padding: 0 38px 0 $spacing--300;

      &--small {
          font-size: $font__size--300;
          height: 28px;
      }

      &--large {
          font-size: $font__size--600;
          height: 40px;
      }
    }
  }
}

@mixin lumen-form--buttons(){
    .lumen-content {
        button,
        input[type="submit"],
        input[type="reset"],
        input[type="button"],
        .lumen-button[class] {
            background: $color__blue--600;
            border: 0;
            color: $color__white;
            -webkit-appearance:none;
            padding: $spacing--50 $spacing--100;
            margin: 0 $spacing--50 $spacing--200 0;
            line-height: $font__line-height--400;
            font-size: $font__size--300;
            border-radius: $border__radius;
            display: inline-block;
            outline: none;
            box-sizing: border-box;
            letter-spacing: 0.1em;
            cursor: pointer;
            //if buttons shouldn't wrap
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            //width needs to be added to container.

            &:hover {
                color: $color__white;
                text-decoration: none;
            }
            &:active, &:focus {
                outline: none;
                box-shadow: 0 0 4px $color__blue--400;
            }

            &.lumen-button--secondary {
                border: $border--m solid $color__black--700;
                background: $color__white--translucent;
                color: $color__black--700;
                padding: ($spacing--50 - $border--m) ($spacing--100 - $border--m);
                font-weight: 500;

                &:hover {
                    color: $color__black--700;
                }
            }

            &.lumen-button--tag {
                background: transparent;
                border: 1px solid $color__black--800;
                color: $color__black--800;
                font-size: $font__size--100;
                font-weight: 400;
                padding: $spacing--50 $spacing--200;
                letter-spacing: 0.05em;

                &:hover,
                &:focus {
                    border-color: $color__blue--600;
                    color: $color__blue--600;
                }

                &--destructive {
                    &:after {
                        @include icon--pseudo-style();
                        content: map-get($icons, 'close');
                        margin-#{$dir-start}: $spacing--50;
                    }
                    &:hover {
                        border-color: $color__utility--red;
                        color: $color__utility--red;
                    }

                }
            }

            &.lumen-button--destructive {
                background: $color__utility--red;
            }
        }
    }
}

// ********************************************************
// These are very very alpha. Don't use unless you are helping to develop this.
// no changes below here will be considered breaking changes
// ********************************************************

$form__border__color: $color__black--400;
$form__icon__color: $color__black--600;
$form__text__color: $color__black--800;
$form__text--secondary__color: $color__black--600;

@mixin lumen-form--100(){
    @include lumen-form--buttons();
    @include lumen-form--select();
    @include lumen-form--label();
}

@mixin lumen-form--200(){
    @include lumen-form--100();
    @include lumen-form--textarea();
    @include lumen-form--list();
}

@mixin lumen-form--select--new() {
    .lumen-content {
        select {
            background-color: $color__white;
            border: 1px solid $form__border__color;
            appearance: none;
            background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='640' height='640' viewBox='0 0 640 640'><style>/* <![CDATA[ */ path { fill: #{rgba($form__icon__color,0.99)}; } /* ]]> */ </style><path d='M553.7,155.92c18.42-17.25,46.08-17.25,63.33,0a43.77,43.77,0,0,1,0,62.2l-266,266c-17.25,17.25-44.91,17.25-63.28,0l-264.84-266a44,44,0,1,1,62.16-62.2L320,389.63Z'/></svg>") no-repeat right 8px center;
            background-size: 10px 10px;
            padding: 2px $spacing--100;
            padding-#{$dir-end}: 30px + $spacing--100;
            border-radius: $border__radius;
            margin-top: 4px;
            font-weight: 500;
        }
    }
}

@mixin lumen-form--label(){
    .lumen-content {
        label {
            font-size: 14px;
            color: $form__text--secondary__color;
        }
    }
}

@mixin lumen-form--textarea(){
    .lumen-content {
        textarea {
            border: 1px solid $form__border__color;
        }
    }
}



@mixin lumen-form--list {

    /*
    ### The html structure for a form list.
    ### this structure has been highly tested on screen readers and voice overs.

        <ol class="lumen-form__list">
            <li class="lumen-form__list__item">
                <label for="text">Text</label>
                <input id="text" type="text">
            </li>
            <li class="lumen-form__list__item">
                <label for="textMessage">
                    Text - message
                    <span class="message">
                        <em>message</em>
                        This is a message on the item.
                    </span>
                </label>
                <input id="textMessage" type="text">
            </li>
            <li class="lumen-form__list__item">
                <label for="textError">
                    Text - error
                    <span class="error">
                        <em>error</em>
                        This is a message on the error state of the item
                    </span>
                </label>
                <input id="textError" type="text">
            </li>
        </ol>

    */

    .lumen-content {
        .lumen-form {
            &__list {
                &__item {
                    margin-top: $spacing--400;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
