// this currently does not dictate widths or layout. this only styles default HTML elements
$dir-start: left !default;
$dir-end: right !default;
$list-style-type: decimal !default;

@mixin content(){
    .lumen-content {
        font-weight: 300;
        font-size: $font__size--500;
        line-height: $font__line-height--500;
        color: $color__black--800;
        font-family: $font__family--sans;
        font-weight: 300;

        @media (min-width: $breakpoint--400){
            line-height: $font__line-height--600;
        }

        &::after {
          content: "";
          display: table;
          clear: both;
        }
        @if $isRTL {
            @include rtl();
        }
        .rtl {
            @include rtl();
        }
        a {
            color: $color__blue--600;
            text-decoration:none;
            &:hover {
                color: $color__blue--600;
                text-decoration:underline;
            }
        }
        b, strong {
            font-weight: $font__weight--semi-bold;
        }
        em {
            font-style: $font__style--italic;
            @if ($font__style--italic-before){
                &:before {
                    content:$font__style--italic-before;
                }
            }
            @if ($font__style--italic-after){
                &:after {
                    content:$font__style--italic-after;
                }
            }
            @if $emAsBold {
                font-weight: $font__weight--bold;
            }
        }
        p, ul, ol {
            margin:0 0 $spacing--400;
        }
        ul {
            padding-#{$dir-start}:20px;
        }
        ol {
            padding-#{$dir-start}:23px;
            list-style-type: $list-style-type;
        }
        li {
            line-height: $font__line-height--500;
            margin: $spacing--300 0;

            &:first-child {
               margin-top: $spacing--200;
            }
        }

        .list--stripped {
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
                list-style: none;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
        .list--inline {
            @extend .list--stripped;
            li {
                display: inline-block;
            }
        }

        dl {

        }
        dt {
            font-size: $font__size--600;
            line-height: $font__line-height--400;
            font-style: $font__style--italic;
            margin-bottom: $spacing--200;
            font-weight:400;
        }
        dd {
            margin:0;
            margin-#{$dir-start}:$spacing--500;
        }
        h1 {
            font-size: $font__size--900;
            font-family: $font__family--serif;
            line-height: $font__line-height--400;
            font-weight: 300;
            margin: 0 0 $spacing--300;
            text-align: center;

            @media (min-width: $breakpoint--400){
                font-size: $font__size--1000;
            }
            @media (min-width: $breakpoint--600){
                font-size: $font__size--1100;
            }
            // temporary solution for while Pahoran and Baskerville are both in use during the  "early adopter" phase
            .gvsg-approved & {
                font-family: $font__family--serif-gvsg;
            }
        }

        h2 {
            font-family: $font__family--sans;
            font-weight: 300;
            margin: $spacing--600 0 $spacing--300 0;
            font-size: $font__size--800;
            line-height: $font__line-height--400;

            @media (min-width: $breakpoint--400){
                margin: $spacing--700 0 $spacing--400 0;
            }
            @media (min-width: $breakpoint--600){
                font-size: $font__size--900;
            }
        }

        h3 {
            font-family: $font__family--serif;
            font-weight: 300;
            margin: $spacing--600 0 $spacing--200 0;
            font-size: $font__size--800;
            line-height: $font__line-height--400;

            @media (min-width: $breakpoint--400){
                margin: $spacing--700 0 $spacing--300 0;
            }
            // temporary solution for while Pahoran and Baskerville are both in use during the  "early adopter" phase
            .gvsg-approved & {
                font-family: $font__family--serif-gvsg;
            }
        }

        h4 {
            font-family: $font__family--sans;
            font-weight: 400;
            margin: $spacing--600 0 $spacing--100 0;
            font-size: $font__size--500;
            line-height: $font__line-height--400;

            @media (min-width: $breakpoint--400){
                margin-bottom: $spacing--200;
            }
        }
        h5 {
            font-family: $font__family--sans;
            font-weight: 300;
            margin: $spacing--600 0 $spacing--100 0;
            font-size: $font__size--500;
            line-height: $font__line-height--400;
            font-style: $font__style--italic;

            @media (min-width: $breakpoint--400){
                margin-bottom: $spacing--200;
            }
        }
        blockquote {
            margin:$spacing--500 0 $spacing--500;
            margin-#{$dir-start}: -15px;
            border-#{$dir-start}:4px solid $color__green--500;
            padding-#{$dir-start}:11px;
            font-style: $font__style--italic;
            em, i {
                font-style:normal;
            }
        }
        img {
            max-width:100%;
            vertical-align: middle;
        }
        hr {
            margin: $spacing--700 auto $spacing--800;
            @include ruleWidth();
            border: none;
            border-bottom: 1px solid $color__black--500;
            //position: relative;

            // &:before, &:after {
            //     content: "";
            //     display: block;
            //     padding: 3px;
            //     background: $color__white;
            //     border-bottom: 1px solid $color__black--700;
            //     border-right: 1px solid $color__black--700;
            //
            //     position: absolute;
            //     left: 50%;
            //     top: 0;
            //     transform: translateX(-50%) translateY(-40%) rotate(45deg);
            // }
            //
            // &:after {
            //     transform: translateX(-50%) translateY(70%) rotate(45deg);
            //     border: 1px solid $color__black--700;
            //
            // }
            //
            // &.topGlyph {
            //     &:before, &:after {
            //         border: none;
            //         border-left: 1px solid $color__black--700;
            //         border-top: 1px solid $color__black--700;
            //
            //         transform: translateX(-50%) translateY(-50%) rotate(45deg);
            //     }
            //     &:after {
            //         transform: translateX(-50%) translateY(-160%) rotate(45deg);
            //         border: 1px solid $color__black--700;
            //     }
            // }
        }

    }

    .lumen-prophetic {
        font-family: $font__family--serif;
        h1,h2,h3,h4,h5,h6 {
            font-family: $font__family--serif;
        }
        h2 {
            font-weight: $font__weight--semi-bold;
        }
        h4 {
            font-weight: normal;
        }
    }
}

@mixin ruleWidth() {
    // needs to use MQ to change width to match current grid
    width: lumen-columns(2,300);

    @media screen and (min-width: $breakpoint--400){
        width: lumen-columns(4,400);
    }

    @media screen and (min-width: $breakpoint--500){
        width: lumen-columns(2,500);
    }

    @media screen and (min-width: $breakpoint--600){
        width: lumen-columns(2,600);
    }
    @media screen and (min-width: $breakpoint--700){
        width: lumen-columns(2,700);
    }
}
