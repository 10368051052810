// RTL-centric variables
$dir-start: left !default;
$dir-end: right !default;

// LUMEN
@import 'libs/lumen-sass/_lumen-sass.scss';
@import 'libs/lumen-icon/_lumen-icon.scss';
@include core();
@include content();
@include fonts--all(M);
@include icons();
@include icon--css();
@include lumen-form--basic();
@import 'libs/lumen-frame/_lumen-frame.scss';
@import 'libs/lumen-sass/_lumen-sass-utilities.scss';
@import 'libs/lumen-content-block/_lumen-content-block.scss';
@import 'libs/lumen-image/_lumen-image.scss';
@import 'libs/lumen-title-block/_lumen-title-block.scss';
@import 'libs/lumen-quote/_lumen-quote.scss';
@import 'libs/publisher-form-admin/_publisher-form-admin.scss';
@import 'libs/lumen-media-block/_lumen-media-block.scss';
@import 'libs/lumen-video/_lumen-video.scss';
@import 'libs/lumen-tile/_lumen-tile.scss';
@import 'libs/lumen-dynamic-layout/_lumen-dynamic-layout.scss';
@import 'libs/spark-section-heading/spark-section-heading.scss';
@import 'libs/spark-feature/spark-feature.scss';
@import 'libs/spark-drawer/_spark-drawer.scss';
@import 'libs/spark-breadcrumb/_spark-breadcrumb.scss';
@import 'libs/spark-link-list/_spark-link-list.scss';
@import 'libs/spark-pagination/spark-pagination.scss';
@import 'libs/spark-author-block/spark-author-block.scss';
@import 'libs/spark-content-actions/_spark-content-actions.scss';
@import 'libs/spark-select/spark-select.scss';
@import 'libs/spark-serp-tile/spark-serp-tile.scss';
@import 'libs/lumen-region/lumen-region.scss';
@import 'libs/spark-image-gallery/spark-image-gallery.scss';
@import 'libs/spark-image-gallery-item/spark-image-gallery-item.scss';
@import 'libs/spark-input/spark-input.scss';
@import 'libs/spark-date-select/spark-date-select.scss';
@import 'libs/spark-textarea/spark-textarea.scss';
@import 'libs/spark-multifile-upload/spark-multifile-upload.scss';
@import 'libs/spark-fieldset/spark-fieldset.scss';
@import 'libs/spark-search-facets/spark-search-facets.scss';
@import 'libs/spark-order-form-module/spark-order-form-module.scss';
@import 'libs/lumen-sub-nav/lumen-sub-nav.scss';

// PROJECT
@import 'components/acp-home-template/acp-home-template.scss';

@import 'components/componentList/componentList.scss';
@import 'components/error/error.scss';

@import 'components/publisher-bar/publisher-bar.scss';
@import 'components/footer/footer.scss';

@import 'components/video-gallery-item/video-gallery-item.scss';

body {
    overflow-x: hidden;
}

.lumen-page-read {
    @include spark-breadcrumb--narrow();
}
.lumen-page-scan, .lumen-page-discover {
    @include spark-breadcrumb--full();
}
