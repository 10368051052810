@use "sass:math";

.spark-author-block {
    display: flex;
    align-items: flex-start;
    margin: $spacing--600 0 $spacing--600;
    width: 100%; //Needed for IE11

    @media (min-width: $breakpoint--400) {
        margin: $spacing--700 0 $spacing--700;
    }

    .lumen-image {
        margin-right: $spacing--300;

        width: lumen-columns(1,300);
        @media (min-width: $breakpoint--400) {
            width: lumen-columns(1,400) + math.div(lumen-gutter(400), 2);
        }
        @media (min-width: $breakpoint--500) {
            width: lumen-columns(1,500) + math.div(lumen-gutter(500), 2);
        }
        @media (min-width: $breakpoint--600) {
            width: lumen-columns(1,600) + math.div(lumen-gutter(600), 2);
        }
        @media (min-width: $breakpoint--700) {
            width: lumen-columns(1,700) + math.div(lumen-gutter(700), 2);
        }
        img {
            border-radius: 50%;
        }
    }

    &__author {
        flex: 1;
    }

    &__author-name[class]{
        font: $font__size--800/#{$font__line-height--400} $font__family--serif;

        margin-bottom: $spacing__200;
        @media (min-width: $breakpoint--400) {
            margin-bottom: $spacing__300;
        }
    }
    &__bio[class] {
        margin-bottom: $spacing__100;
    }

    &__bio {
        color: $color__black--600;
        font-size: $font__size--400;
        font-style: italic;
    }
}
