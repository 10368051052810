.spark-section-heading {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__heading {
        @extend h1;
    }
    &__visual {
        margin-bottom: $spacing--300;
        border-radius: 50%;
        overflow: hidden;
        width: lumen-columns(2,300);
        @media (min-width: $breakpoint--400) {
            width: lumen-columns(2,400,true);
        }
        @media (min-width: $breakpoint--500) {
            width: lumen-columns(2,500);
        }
        @media (min-width: $breakpoint--600) {
            width: lumen-columns(2,600);
        }
        @media (min-width: $breakpoint--700) {
            width: lumen-columns(2,700);
        }
    }
}
