@use "sass:math";

$height-ratio: math.div(5, 6);

@function column-and-half-gutter($columns, $breakpoint) {
    @return lumen-columns($columns,$breakpoint) + math.div(lumen-gutter($breakpoint), 2);
}

.spark-serp-tile {
    // BLOCK
    border-bottom: 1px solid $color__black--500;
    margin: $spacing--400 0 $spacing--500;

    .lumen-frame--full & {
        @media (min-width: $breakpoint--400){
            width: lumen-columns(12, 400);
        }
        @media (min-width: $breakpoint--500){
            width: lumen-columns(8, 500);
        }
        @media (min-width: $breakpoint--600){
            width: lumen-columns(8, 600);
        }
        @media (min-width: $breakpoint--700){
            width: lumen-columns(8, 700);
        }
    }

    // ELEMENTS
    &, &__link {
        display:flex;
        flex-flow: wrap row;
        width: 100%;
    }

    &__link {
        &:hover[class] {
            text-decoration: none;
        }
    }

    &__title {
        //color explicitly set so it doesn't show up blue when it is wholeLinked
        color: $color__black--800;
        flex: 100%;
        font: 400 $font__size--600#{"/"}$font__line-height--400 $font__family--sans;
        margin-bottom: $spacing__200;

        @media screen and (min-width: $breakpoint--400) {
            font-size: $font__size--700;
        }
    }

    &__metadata {
        color: $color__black--600;
        flex: 100%;
        font: 400 $font__size--200#{"/"}$font__line-height--500 $font__family--sans;
        margin-bottom: $spacing--200;

        @media (min-width: $breakpoint--400) {
            margin-bottom: $spacing--300;
        }

        p:last-child{
            margin-bottom: 0;
        }
    }

    &__image-wrapper {
        height: lumen-columns(1, 300, true) * $height-ratio; //for the 6:5 ratio
        overflow: hidden;
        position: relative;
        width: lumen-columns(1, 300, true);
        margin-bottom: $spacing--500;
        margin-#{$dir-end}: $spacing--300;

        @media (min-width: $breakpoint--400) {
            height: column-and-half-gutter(2,400) * $height-ratio;
            width: column-and-half-gutter(2,400);
            margin-#{$dir-end}: math.div(lumen-gutter(400), 2);
        }
        @media (min-width: $breakpoint--500) {
            height: column-and-half-gutter(2,500) * $height-ratio;
            width: column-and-half-gutter(2,500);
            margin-#{$dir-end}: math.div(lumen-gutter(500), 2);
        }
        @media (min-width: $breakpoint--600) {
            height: column-and-half-gutter(1,600) * $height-ratio;
            width: column-and-half-gutter(1,600);
            margin-#{$dir-end}: math.div(lumen-gutter(600), 2);
        }
        @media (min-width: $breakpoint--700) {
            height: column-and-half-gutter(1,700) * $height-ratio;
            width: column-and-half-gutter(1,700);
            margin-#{$dir-end}: math.div(lumen-gutter(700), 2);
        }

        .lumen-icon {
            align-items: flex-end;
            color: $color__white;
            cursor: pointer;
            font-size: $font__size--500;
            height: 100%;
            line-height: 1;
            margin: (-1*$spacing--200) 0 0 $spacing--200;
            position: absolute;
            z-index: $layer--200;

            &__graphic:before {
                background: $color__black--translucent;
            }

            @media (min-width: $breakpoint--400) {
                font-size: $font__size--700;
            }
        }
    }

    &__content {
        //color explicitly set so it doesn't show up blue when it is wholeLinked
        color: $color__black--800;
        flex: 1 1 1%; //1% for IE11 bug
        font: 300 $font__size--400#{"/"}$font__line-height--500 $font__family--sans;
        margin-bottom: $spacing--500;

        p:last-child{
            margin-bottom: 0;
        }
    }

}
