.spark-input {
    color: $color__black--700;
    display: block;
    margin-bottom: $spacing--600;

    &__label {
        display: block;
        font-size: $font__size--200;
        margin-bottom: $spacing--50;
    }

    &--checkbox {
        margin-bottom: $spacing--50;
    }

    input {
        border: 1px solid $color__black--500;
        border-radius: $border__radius;
        font-size: $font__size--400;
        padding: $spacing--50;
    }
}
