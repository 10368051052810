.spark-search-facets {
    &[class] {
        @include lumen-fullbleed-background(full);
        border-bottom: 1px solid $color__black--400;
        font-size: $font__size--300;
        list-style: none;
        margin-bottom: $spacing--600;
        padding: 0;
    }

    &__item {
        &[class] {
            border-bottom: 2px solid $color__white;
            display: inline-block;
            margin: 0 $spacing--600 0 0;
            padding: $spacing--100 0 (#{$spacing--100 - 2px});

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            a {
                color: $color__black--800;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &--active[class],
        &:hover {
            border-bottom-color: $color__blue--500;
        }
    }
}
