.lumen-title-block {
    margin-top: $spacing--700;
    text-align: center;
    margin-bottom: $spacing--700;

    .lumen-media-block {
        text-align: left;
        margin: 0;
        margin-bottom: $spacing--600;
        width: auto;

        img {
            min-width: 100%;
        }
    }

    .lumen-video {
        @media (max-width: $breakpoint--400) {
            width: 100vw;
            margin-#{$dir-start}: - lumen-gutter(300, true);
        }
    }

    &__pre-title {
        display: inline-block;
        margin-bottom: $spacing--200;
    }

    &__byline[class]  {
        margin-bottom: $spacing--600;
    }

    &__lede[class] {
        margin-bottom: $spacing--500;
        font-family: $font__family--serif;
    }
    &__epigraph[class] {
        margin-bottom: $spacing--600;
        font-family: $font__family--sans;
        color: $color__black--800;
    }

    &__epigraph[class],
    &__lede[class] {
        font-size: $font__size--600;
        @media (min-width: $breakpoint--400){
            font-size: $font__size--700;
            text-align: center;
        }
    }

    &--index { // index pages don't need large gaps
        margin-bottom: $spacing--400;
    }

    // --fit-smallest behaves the same as if it were not in the overlay
    &--overlay:not(&--fit-smallest) {
        color: $color__white;
        margin-top: $spacing--700;

        @media (max-width: $breakpoint--400){
            color: $color__black--800;
            margin-top: 0;

            .lumen-title-block {
                &__title-wrapper {
                    background: transparent;

                    .lumen-media-block .lumen-image[class] {
                        background: transparent;
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        @media (min-width: $breakpoint--400){
            .lumen-title-block__heading-wrapper {
                padding: $spacing--500 lumen-columns(1,400,true) 0;
            }
            .lumen-title-block__epigraph {
                padding: 0 lumen-columns(1,400,true);
            }
        }
        @media (min-width: $breakpoint--400) and (max-width: $breakpoint--500){
            .lumen-media-block .lumen-image[class] {
                background: $color__black--800;
                img {
                    opacity: 0.75;
                }
            }
        }

        @media (min-width: $breakpoint--500){
            .lumen-title-block__heading-wrapper {
                padding: $spacing--700 lumen-columns(1,500,true) 0;
            }
            .lumen-title-block__epigraph {
                padding: 0 lumen-columns(2,500,true);
            }
        }

        @media (min-width: $breakpoint--600){
            .lumen-title-block__heading-wrapper {
                padding: $spacing--700 lumen-columns(2,600,true) 0;

            }
            .lumen-title-block__epigraph {
                padding: 0 lumen-columns(3,600,true);
            }
        }

        @media (min-width: $breakpoint--700){
            .lumen-title-block__heading-wrapper {
                padding: $spacing--700 lumen-columns(2,700,true) 0;
            }
            .lumen-title-block__epigraph {
                padding: 0 lumen-columns(3,700,true);
            }
        }

        .lumen-title-block {

            &__title-wrapper {
                position: relative;

                @media (max-width: $breakpoint--400){
                    background: transparent;
                }

                .lumen-media-block {

                    .lumen-image {
                        background: $color__black--800;
                        img {
                            opacity: 0.9;
                        }
                    }

                    &__caption,
                    &__download {
                        display: none;
                    }
                    > [class*="spark-drawer"]{
                        display: none;
                    }

                }
            }
            &__heading-wrapper[class] {
                position: absolute;
                z-index: $layer--200;
                bottom: 0;
                background: $color__black--translucent--dark;
                background: transparent linear-gradient(transparent, $color__black--translucent--dark);
                width: 100%;
                padding-top: $spacing--700;
                pointer-events: none;

                a {
                    pointer-events: all;
                }

                @media (max-width: $breakpoint--400){
                    position: static;
                    background: transparent;
                    color: $color__black--800;
                }
            }

            &__pre-title {
                display: inline-block;
                margin-bottom: $spacing--200;
                pointer-events: none;

                a {
                    pointer-events: all;
                }
            }

            &__heading[class] {
                margin: 0 0 $spacing--400 0;
                pointer-events: none;

                a {
                    pointer-events: all;
                }
            }

            &__byline[class]  {
                margin-bottom: $spacing--600;
                pointer-events: none;

                a {
                    pointer-events: all;
                }
            }

            &__lede[class] {
                margin-bottom: $spacing--600;
                pointer-events: none;

                a {
                    pointer-events: all;
                }

                @media (max-width: $breakpoint--400){
                    text-align: left;
                }
            }

        }

        .spark-content-actions {
            pointer-events: auto;
            .lumen-icon__graphic,
            .lumen-icon__graphic--facebook:before,
            .lumen-icon__graphic--twitter:before,
            .lumen-icon__graphic--google-plus:before {
                color: $color__white;
            }
        }

        &.lumen-title-block--overlay--play {
            .lumen-title-block {
                &__heading-wrapper[class] {
                    position: static;
                    background: transparent;
                    width: 100%;
                    pointer-events: none;
                    color: $color__black--800;

                    a {
                        pointer-events: all;
                    }
                }

                &__pre-title,
                &__heading[class],
                &__byline[class],
                &__lede[class] {
                    pointer-events: auto;
                }
            }
            .spark-content-actions {
                .lumen-icon__graphic {
                    color: inherit
                }
                .lumen-icon__graphic--facebook:before {
                    color: $color__facebook;
                }
                .lumen-icon__graphic--twitter:before {
                    color: $color__twitter;
                }
            }
            .lumen-media-block {
                &__caption,
                &__download {
                    display: block;
                }
                > [class*="spark-drawer"]{
                    display: block;
                }
            }
        }
        // **** MJS-MAKE-FIT Classes *****//

        &.lumen-title-block--fit-small {
            .lumen-media-block .lumen-image[class] {
                background: $color__black--800;
                img {
                    opacity: 0.75;
                }
            }
            .lumen-title-block {
                &__heading-wrapper {
                    padding-top: $spacing--700;
                }
                &__heading[class] {
                    font-size: $font__size--1000;
                }
                &__byline {
                    margin-bottom: $spacing--500;
                    font-size: $font__size--400;
                }
                &__lede {
                    font-size: $font__size--600;
                    margin-bottom: $spacing--400;
                }
            }
        }
        &.lumen-title-block--fit-smaller {
            .lumen-media-block .lumen-image[class] {
                background: $color__black--800;
                img {
                    opacity: 0.7;
                }
            }
            .lumen-title-block {
                &__heading-wrapper {
                    padding-top: $spacing--500;
                }
                &__heading {
                    font-size: $font__size--900;
                }
                &__byline {
                    margin-bottom: $spacing--400;
                    font-size: $font__size--300;
                }
                &__lede {
                    font-size: $font__size--500;
                    margin-bottom: $spacing--300;
                }
            }
        }
    }
}

@mixin lumen-title-block--narrow() {
    // have the image inside of a title-block go out one column at larger sizes
    .lumen-title-block:not(.lumen-title-block--overlay) .lumen-media-block {
        @include breakout-both();
    }

    .lumen-title-block--overlay {
        @media (min-width: $breakpoint--400){
            width: lumen-columns(12,400);
            margin-#{$dir-start}: - lumen-columns(1,400, true);
        }
        @media (min-width: $breakpoint--500){
            width: lumen-columns(12,500);
            margin-#{$dir-start}: - lumen-columns(2,500, true);
        }

        @media (min-width: $breakpoint--600){
            width: lumen-columns(12,600);
            margin-#{$dir-start}: - lumen-columns(3,600, true);
        }

        @media (min-width: $breakpoint--700){
            width: lumen-columns(12,700);
            margin-#{$dir-start}: - lumen-columns(3,700, true);
        }
    }
}
