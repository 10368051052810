$icon-size--400: 44px;
$icon-size--600: 60px;
$icon-spacing--wide: $spacing--400;
$icon-spacing--narrow: $spacing--300;


.lumen-video {
    position: relative;

    &__length {
        position: absolute;
        bottom: $spacing--400;
        right: $spacing--400;
        background: $color__black--translucent--dark;
        color: $color__white;
        border-radius: $border__radius;
        padding: $spacing--50 $spacing--50*2;
        z-index: 10;
    }

    .lumen-icon {
        color: $color__white;
        position: absolute;
        bottom: $spacing--400;
        left: $spacing--400;
        font-size: $icon-size--400;
        line-height: 1;
        z-index: 10;

        @media (min-width: $breakpoint--400) {
            font-size: $icon-size--600;
        }

        &__graphic {
            &:before {
                background: rgba($color__black--800, 0.3);
                box-sizing: content-box;
                max-width: none;
                text-align: center;
                height: $icon-size--400;
                width: $icon-size--400;

                @media (min-width: $breakpoint--400) {
                    height: $icon-size--600;
                    width: $icon-size--600;
                }
            }
        }

    }
    .lumen-image__wrapper {
        background: $color__black--800;
        text-align: center;
    }
}

@if $hasEQCSS {
    @element ".lumen-video" and (max-width:400px) {
        eq_this {
            .lumen-icon{
                &[class] {
                    font-size: 40px;
                    left: $icon-spacing--narrow;
                    bottom: $icon-spacing--narrow;
                }
                &__graphic[class]:before {
                    height: 40px;
                    width: 40px;
                }
            }
            .lumen-video__length {
                right: $icon-spacing--narrow;
                bottom: $icon-spacing--narrow;
            }
        }
    }
}

.lumen-content {
    .lumen-video {
        button[class]:not(.vjs-big-play-button) {
                background: inherit;
                border: inherit;
                color: inherit;
                display: inherit;
                overflow: inherit;
                font-size: inherit;
                line-height: inherit;
                text-transform: inherit;
                text-decoration: inherit;
                transition: inherit;
                appearance: inherit;
        }
    }
}
