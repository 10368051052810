@use "sass:math";

.lumen-tile {

    // BLOCK
    margin-bottom: $spacing__600;
    border-bottom:1px solid $color__black--500;
    width: 100%;

    // ELEMENTS
    &__link {
        display:block;

        &:hover[class] {
            text-decoration: none;
        }
    }

    &__title {
        //color explicitly set so it doesn't show up blue when it is wholeLinked
        color: $color__black--800;
        font: italic 700 $font__size--700#{"/"}$font__line-height--400 $font__family--sans;
        margin: $spacing__300 0 $spacing__200;

        &[class] a, &[class] a:hover {
            color: $color__black--800;
            text-decoration: none;
        }
    }

    &__content {
        //color explicitly set so it doesn't show up blue when it is wholeLinked
        color: $color__black--800;
        font: 300 $font__size--400#{"/"}$font__line-height--500 $font__family--sans;
        margin: $spacing__200 0 $spacing--300;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__metadata {
        color: $color__black--600;
        font: 500 $font__size--300#{"/"}$font__line-height--500 $font__family--sans;
        margin-top: $spacing--300;

        p:last-child{
            margin-bottom: 0;
        }
    }

    .lumen-image {
        margin-bottom: 0;
    }

    &__text-wrapper {
        padding-bottom: $spacing__500;
    }

    &__image-wrapper {
        position: relative;

        .lumen-icon {
            position: absolute;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;

            cursor: pointer;
            color: $color__white;
            font-size: 74px;
            z-index: $layer--200;

            &__graphic:before {
                background: $color__black--translucent;
                box-sizing: content-box;
                width: 1em;
                text-align: center;
                border: 0.08em solid rgba($color__black--translucent, 0.0);
            }
        }
    }

    // MODIFIERS

    &--horizontal {
        margin-bottom: $spacing__200;

        &, .lumen-tile__link {
            display:flex;
            flex-direction: row;
            width: 100%;
        }
        .lumen-tile {
            &__image-wrapper {
                width: lumen-columns(1,300) + math.div($grid__gutter-width--300, 2);
                margin-#{$dir-end}: lumen-gutter(300) - math.div($grid__gutter-width--300, 2);
                margin-bottom: $spacing__200;
                .lumen-icon {
                    display: none;
                }
            }

            @media (min-width: $breakpoint--400) {
                &__image-wrapper {
                    width: lumen-columns(2,400) + math.div($grid__gutter-width--400, 2);
                    margin-#{$dir-end}: lumen-gutter(400) - math.div($grid__gutter-width--400, 2);
                }
            }
            @media (min-width: $breakpoint--500) {
                &__image-wrapper {
                    width: lumen-columns(1,500) + math.div($grid__gutter-width--500, 2);
                    margin-#{$dir-end}: lumen-gutter(500) - math.div($grid__gutter-width--500, 2);
                }
            }
            @media (min-width: $breakpoint--600) {
                &__image-wrapper {
                    width: lumen-columns(1,600) + math.div($grid__gutter-width--600, 2);
                    margin-#{$dir-end}: lumen-gutter(600) - math.div($grid__gutter-width--600, 2);
                }
            }
            @media (min-width: $breakpoint--700) {
                &__image-wrapper {
                    width: lumen-columns(1,700) + math.div($grid__gutter-width--700, 2);
                    margin-#{$dir-end}: lumen-gutter(700) - math.div($grid__gutter-width--700, 2);
                }
            }
            .lumen-image {
                margin-bottom: $spacing__200;
            }

            &__title {
                margin: 0 0 $spacing__50;
                font-size: $font__size--400;
                font-style: normal;
            }
            &__content {
                margin: 0 0 $spacing__200;
                font-size: $font__size--400;
            }
            &__metadata {
                margin: 0;
                font-size: $font__size--300;
            }

            &__text-wrapper {
                // width: lumen-columns(3,300);
                flex-grow: 1;
                width:1px; // somew weird bug where it needs to be a small number to be the remaining space
                padding: 0;

                & > *:last-child {
                    margin: 0 0 $spacing__200;
                }
            }
        }
    }

    &--spot {
        &, > a.lumen-tile__link {
            text-align: center;
            border-bottom: none;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .lumen-tile {

            &__title {
                font-size: $font__size--700;
                font-family: $font__family--sans;
                line-height: $font__line-height--400;
                font-weight: 300;
                color: $color__black--800; //color explicitly set so it doesn't show up blue when it is wholeLinked
                font-style: normal;

                &[class] a, &[class] a:hover {
                    color: $color__black--800;
                    text-decoration: none;
                }
            }

            &__image-wrapper {
                margin-bottom: $spacing--300;
                border-radius: 50%;
                overflow: hidden;
                max-width: lumen-columns(2,300);
                width: 70%;

                @media (min-width: $breakpoint--400) {
                    max-width: lumen-columns(2,400,true);
                }
                @media (min-width: $breakpoint--500) {
                    max-width: lumen-columns(2,500);
                }
                @media (min-width: $breakpoint--600) {
                    max-width: lumen-columns(2,600);
                }
                @media (min-width: $breakpoint--700) {
                    max-width: lumen-columns(2,700);
                }

                .lumen-image {
                    margin-bottom: 0;
                }

                .lumen-icon {
                    display: none;
                }
            }
        }
    }

    &--card {
        background-color: $color__white;
        border-bottom: 0;

        .lumen-tile {
            &__text-wrapper {
                margin-left: $spacing--300;
                margin-right: $spacing--300;
            }
        }
    }

    &--selected {
        .lumen-image {
            box-shadow: inset 0 0 0 5px $color__blue--400;
            padding: 5px;
        }
    }

}
