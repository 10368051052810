
.publisher-bar {

    #ldse-toolbar-container.ldse-toolbar-container {
        position: static;

        .ldse-toolbar{
            position: static;
            background: $color__black--200;
            box-shadow: none;
            border-bottom: 1px solid $color__black--300;
            display: flex;

        }
        .ldse-toolbar-toggle {
            display: none;
        }
        .ldse-main-menu {
            border: none;

            .ldse-toolbar-logo {
                margin: 0;
                padding: 0;
                position: relative;
                .ldse-icon {
                    display: inline-block;
                    align-self: flex-start;
                    flex: none;
                    margin-top: 3px;
                }
                .ldse-brand {
                    display: inline-block;
                    vertical-align: top;
                    padding-top: 10px;

                }
                .ldse-site-name {
                    display: inline-block;
                    width: auto;
                    position: absolute;
                    left: 38px;
                    text-align: left;
                    padding: 0;
                }
                > span {
                    // flex-grow: 0;
                    letter-spacing: 0.2em;
                }
            }
            ul.ldse-menu {
                position: relative !important;
                top: 0;
                //margin-top: -5px;
                border-radius: 0;
                background: $color__black--200;
                box-shadow: none;
                border: 1px solid $color__black--400;
                border-top: none;
                border-left: none;
                display:none;
                z-index: 1024;

                li, li a {
                    background: $color__black--300;
                    border-bottom-color: $color__white;
                    color: $color__black--800;
                }
            }
        }

        .ldse-brand {
            position: static;
            line-height: $font__line-height--400;
            span {
                color: $color__black--800;
            }
        }
        .ldse-site-name {
            position: static;
        }

        .ldse-toolbar-item:hover,
        .ldse-toolbar-item.ldse-open {
            background: transparent;
        }

        .ldse-toolbar-buttons {
            position: static;
            display: flex;
            align-items: center;

            button {
                @extend .lumen-button;
                font-family: $font__family--sans;
                min-height: 0;
                &:before {
                    display: none;
                }
            }
        }
    }
}
body {
    margin-top: 0 !important;
}
