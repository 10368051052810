.spark-image-gallery-item {
    text-align: left;

    &.selected {
        position: relative;

        // arrow pointing to selected image
        &:after {
            content: '';
              position: absolute;
              bottom: -1 * $spacing--300;
              left: 50%;
              transform: translateX(-50%);
              width: 0;
              height: 0;
              border-left: $spacing--300 solid transparent;
              border-right: $spacing--300 solid transparent;
              border-bottom: $spacing--300 solid $color__black--800;
        }

        .spark-image-gallery-item__drawer {
            display: block;
        }
    }

    &__drawer {
        background: $color__black--800;
        color: $color__black--200;
        display: none;
        padding: $spacing--600;
        position: absolute;
        width: lumen-columns(4, 300);

        @media (min-width: $breakpoint--400) {
            padding: $spacing--700;
            width: lumen-columns(12, 400);
        }
        @media (min-width: $breakpoint--500) {
            padding: $spacing--800;
            width: lumen-columns(10, 500);
        }
        @media (min-width: $breakpoint--600) {
            width: lumen-columns(8, 600);
        }
        @media (min-width: $breakpoint--700) {
            width: lumen-columns(8, 700);
        }

        .lumen-frame--full & {
            @media (min-width: $breakpoint--400) {
                width: lumen-columns(12, 400);
            }
            @media (min-width: $breakpoint--500) {
                width: lumen-columns(12, 500);
            }
            @media (min-width: $breakpoint--600) {
                width: lumen-columns(12, 600);
            }
            @media (min-width: $breakpoint--700) {
                width: lumen-columns(12, 700);
            }
        }

        &__close {
            position: absolute;
            right: $spacing--300;
            top: $spacing--200;
            cursor: pointer;
        }

        &__download {
            @media (min-width: $breakpoint--400) {
                text-align: right;
            }

            .lumen-icon {
                cursor: pointer;
            }
        }

        &__title {
            display: block;
            font-size: $font__size--900;
            margin-bottom: $spacing--300;
        }

        &__description {
            font-size: $font__size--400;
        }

        &--downloads-active {
            .spark-image-gallery-item {
                &__downloads {
                    display: block;
                }

                &__drawer {
                    &__download {
                        color: $color__black--700;

                        .lumen-icon {
                            cursor: default;
                        }
                    }

                    &__description + .lumen-image {
                        display: none;
                    }
                }
            }
        }

        .lumen-image {
            display: none;

            @media (min-width: $breakpoint--400) {
                display: block;
                margin-bottom: $spacing--400;
                max-height: 50vh;
            }
        }

        .lumen-image__wrapper {
            background: $color__black--translucent;
            text-align: center;
        }

        .lumen-image__image {
            max-height: 50vh;
            width: auto;
        }

        .lumen-icon__graphic--close-open {
            font-size: $font__size--1000;
            padding: $spacing--200;
            line-height: 1;
        }
    }

    &__downloads {
        background: $color__black--800;
        display: none;
        height: 100vh;
        padding: $spacing--400;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: $layer--500;

        @media (min-width: $breakpoint--400) {
            background: $color__black--800t;
            height: auto;
            margin-bottom: $spacing--400;
            position: relative;
        }

        &__close {
            position: absolute;
            right: $spacing--300;
            top: $spacing--200;
            cursor: pointer;
        }

        &__title {
            display: block;
            margin: $spacing--700 0 $spacing--500;

            @media (min-width: $breakpoint--400) {
                margin: $spacing--500 0;
            }
        }

        &__buttons {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin: $spacing--500 0;

            &--3-columns {
                .lumen-button[class] {
                    @media (min-width: $breakpoint--400) {
                        flex: 0 0 calc(33.3333333333333333% - #{$spacing--100});
                    }
                }
            }

            &--4-columns {
                .lumen-button[class] {
                    @media (min-width: $breakpoint--400) {
                        flex: 0 0 calc(25% - #{$spacing--100});
                    }
                }
            }

            .lumen-button {
                &[class] {
                    font-size: $font__size--400;
                    flex: 0 0 calc(50% - #{$spacing--100});
                    margin: 0 0 $spacing--300;
                }

                &__details[class] {
                    display: block;
                    font-size: $font__size--100;
                    margin-top: $spacing--50;
                    white-space: normal;
                }
            }
        }

        &__info[class] {
            color: $color__black--400;
            font-size: $font__size--300;
            margin: $spacing--500 0;

            @media (min-width: $breakpoint--400) {
                margin: $spacing--600 0;
            }

            a {
                font-weight: 600;
            }
        }
    }
}
