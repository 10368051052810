.components-list {
    h1 {
        margin-top: $spacing--800;
    }
    &__search {
        margin-bottom: $spacing--300;
        padding: $spacing--200;
        width: 100%;
    }

    &__link {
        text-align: center;
        display:flex;
        text-transform: capitalize;
        border: 1px solid $color__black--300;
        margin: $spacing--200 0;
        overflow: hidden;
        font-size: $font__size--400;


        a {
            color: $color__black--800;
            padding: 45px 5px;
            align-self: center;
            flex-grow: 1;
            &:hover,
            &:active {
                background: $color__black--200;
                text-decoration: none;
                color: $color__black--800;
            }
        }
        &.hidden {
            display: none;
        }

        &--alpha, &--lumen, &--spark, &--deprecated, &--mjs {
            position: relative;
            overflow: hidden;
            ::before {
                display: block;
                position: absolute;
                color: white;
                transform: rotate(-45deg);
                text-transform: uppercase;
                font-size: $font__size--100;
                top: 15px;
                left: -1 * 28px;
                padding: $spacing--50 40px;
            }
        }
        &--alpha {
            ::before {
                content: "alpha";
                background: $color__green--400;
            }
        }
        &--lumen {
            ::before {
                content: "lumen";
                background: $color__blue--500;
            }
        }
        &--spark {
            ::before {
                content: "spark";
                background: $color__orange--500;
                color: $color__white;
            }
        }
        &--deprecated {
            ::before {
                content: "depricated";
                background: $color__utility--red;
                top: 18px;
                padding: $spacing--50 30px;
            }
        }
        &--mjs {
            ::before {
                content: 'mjs';
                background: $color__black--600;
                top: 12px;
                padding: $spacing--50 48px;
            }
        }
    }
}
