@mixin lumen-dynamic-layout() {
    .lumen-dynamic-layout {
        margin: $spacing--700 0;
        [class$="--major"] &,
        [class$="--minor"] & {
            margin-top: 0;
            &__heading[class] {
                margin-top: 0;
            }
        }
        [class$="--minor"] & {
            .lumen-dynamic-layout__items {
                display: block;
            }
        }
        &__heading {
            @at-root h2#{&}[class],h3#{&}[class],h4#{&}[class],& {
                // nasty selector but need to trump the .lumen-content h#
                display: block;
                font-family: $font__family--sans;
                font-weight: 700;
                margin: $spacing--700 0 $spacing--400;
                font-size: $font__size--400;
                line-height: $font__line-height--400;
                letter-spacing: 0.05em;
                text-transform: uppercase;
            }
        }
        &__description {
            margin: $spacing--50 0 $spacing--400;
        }
        .lumen-dynamic-layout__items {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            &:after,
            &:before {
                // this adds extra children to fill in gaps for 3 and 4 column layouts.
                // Make sure to set the sizes for these when setting the size for other child elements
                content: "";
                order: 999;
                height: 0;
                //Fix for IE11 on windows 7.
                display: block;
            }
            .lumen-dynamic-layout__item {
                display: flex;
                margin-bottom: $spacing--400;
                > * {
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &--mansory {
            background-color: $color__black--200;
        }
        
        &--masonry,
        &:not([class$="--major"] &),
        &:not([class$="--minor"] &) {
            margin-bottom: 0;
            margin-top: 0;
            .lumen-frame--full & {
                padding: $spacing--600 0;
            }
            .lumen-frame--narrow & {
                padding: $spacing--700 0;
            }
        }

        .lumen-content & &__show-more[class]{
            display: none;
        }

        &__show-more {
            position: absolute;
            bottom: -1 * $spacing--400;
            left: 50%;
            transform: translateX(-50%);
            display: none;
        }
        & &__items--max-enabled {
            .lumen-dynamic-layout__show-more[class] {
                display: inline-block;
            }
            .lumen-dynamic-layout__item.lumen-dynamic-layout__item--last-shown-item {
                & ~ * {
                    &:not(.lumen-dynamic-layout__show-more) {
                        display: none;
                    }
                }
            }
        }
    }
}
@mixin lumen-dynamic-layout--2-column($frame) {
    .lumen-dynamic-layout--2-column .lumen-dynamic-layout__wrapper {
        .lumen-dynamic-layout__item,
        .lumen-dynamic-layout__items:after,
        .lumen-dynamic-layout__items:before {
            @if $frame == narrow {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(5,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(4,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(3,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(3,700);
                }
            }
            @if $frame == full {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(6,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(6,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(6,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(6,700);
                }
            }
            @if $frame == split {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(8,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(4,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(4,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(4,700);
                }
            }
        }
    }
}
@mixin lumen-dynamic-layout--3-column--container() {
     @media (min-width: $breakpoint--400){
        //break out 1 column that gets us to 12.
        width: lumen-columns(12, 400);
        margin-left: -1 * lumen-columns(1, 400, true);
    }
    @media (min-width: $breakpoint--500) {
        width: auto;
        margin-left: 0;
    }
    @media (min-width: $breakpoint--600) {
        width: auto;
        margin-left: 0;
    }
}
@mixin lumen-dynamic-layout--3-column($frame) {
    .lumen-dynamic-layout--3-column .lumen-dynamic-layout__wrapper {
        @if $frame == narrow {
             @include lumen-dynamic-layout--3-column--container();
            .lumen-dynamic-layout__item,
            .lumen-dynamic-layout__items:after,
            .lumen-dynamic-layout__items:before {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(4,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(4,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(2,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(2,700);
                }
            }
        }
        @if $frame == full {
            .lumen-dynamic-layout__item,
            .lumen-dynamic-layout__items:after,
            .lumen-dynamic-layout__items:before {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(6,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(4,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(4,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(4,700);
                }
            }
        }
        @if $frame == split {
            .lumen-dynamic-layout__item,
            .lumen-dynamic-layout__items:after,
            .lumen-dynamic-layout__items:before {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(8,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(4,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(4,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(4,700);
                }
            }
        }
    }
}
@mixin lumen-dynamic-layout--4-column--container() {
     @media (min-width: $breakpoint--400){
        //break out 1 column that gets us to 12.
        width: lumen-columns(12, 400);
        margin-left: -1 * lumen-columns(1, 400, true);
    }
    @media (min-width: $breakpoint--500) {
        width: lumen-columns(12, 500);
        margin-left: -1 * lumen-columns(2, 500, true);
    }
    @media (min-width: $breakpoint--600) {
        width: lumen-columns(8, 600);
        margin-left: -1 * lumen-columns(1, 600, true);
    }
    @media (min-width: $breakpoint--700) {
        width: lumen-columns(8, 700);
        margin-left: -1 * lumen-columns(1, 700, true);
    }
}
@mixin lumen-dynamic-layout--4-column($frame) {
    .lumen-dynamic-layout--4-column .lumen-dynamic-layout__wrapper {
        @if $frame == narrow {
             @include lumen-dynamic-layout--4-column--container();
            .lumen-dynamic-layout__item,
            .lumen-dynamic-layout__items:after,
            .lumen-dynamic-layout__items:before {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(6,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(3,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(2,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(2,700);
                }
            }
        }
        @if $frame == full {
            .lumen-dynamic-layout__item,
            .lumen-dynamic-layout__items:after,
            .lumen-dynamic-layout__items:before {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(6,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(3,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(3,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(3,700);
                }
            }
        }
        @if $frame == split {
            .lumen-dynamic-layout__item,
            .lumen-dynamic-layout__items:after,
            .lumen-dynamic-layout__items:before {
                width: lumen-columns(4,300);
                @media (min-width: $breakpoint--400) {
                    width: lumen-columns(8,400);
                }
                @media (min-width: $breakpoint--500) {
                    width: lumen-columns(4,500);
                }
                @media (min-width: $breakpoint--600) {
                    width: lumen-columns(4,600);
                }
                @media (min-width: $breakpoint--700) {
                    width: lumen-columns(2,700);
                }
            }
        }
    }
}
