$className: hide-items;

.#{$className} {
    &__label:empty ~ .lumen-icon {
        display: none;
    }

    &__last-shown {
        & ~ * {
            display: none;
        }

        & ~ .#{$className}__trigger {
            display: initial;
        }
    }
}
