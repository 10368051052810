@keyframes ajax-indication {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.publisher-form-admin {
    padding-top: $spacing--900;
    @include lumen-frame--full();
    @include lumen-form--basic();

    .lumen-content & .ajaxn[class],
    &__header .ajaxn[class] {

        &:after {
            @include icon--pseudo-style();
            content: '#{map-get($icons, refresh)}';
            animation: ajax-indication 1s infinite ease-in-out;
        }
    }

    &__header {
        background: $color__white;
        padding: $spacing--300;

        .components__search {
            width: lumen-columns(4,400);
            padding: $spacing--50;
            margin-left: $spacing--300;
        }
    }

    &__update-all {
        float: right;
    }

    &__metadata-section {
        display: none;
        background: $color__white;
        padding: $spacing--300;
        margin-bottom: $spacing--300;

        &.active {
            display: block;
        }
    }

    &__code-container {
        background: $color__black--800;
        color: $color__white;
        font-size: $font__size--300;
        max-height: 500px;
        overflow: auto;
        padding: 0;
        margin: $spacing--300 0;
        pre {
            margin: 0;
            padding: $spacing--200;
            white-space: pre-wrap;
        }
    }

    .invalid {
        color: $color__red--400;
    }
    .hidden {
        display: none;
    }
    &__component-item {
        background: $color__black--200;
        margin: $spacing--400 0 $spacing--800;
        padding: $spacing--300;

        h2 {
            margin-top: 0 !important;

            &:after {
                content: 'show details';
                font-size: $font__size--400;
                float: right;
            }
            &.active {
                &:after {
                    content: 'hide details';
                }
            }
        }
    }

    &__action-row {
        text-align: right;

        .lumen-button--destructive {
            float: left;
        }
    }

    .unlist {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__form-data {
        background: $color__white;
        padding: $spacing--400;
        margin: $spacing--500 0;

        h3 {
            margin-top: 0 !important;
        }
    }
}
