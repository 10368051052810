@use "sass:math";

.spark-content-actions {
    margin: $spacing--500 0;
    position: relative;
    text-align: center;

    &__section{
        display: inline-block;;

        &:first-child {
            padding-left: 0;
        }

        &:not(:first-child) {
            margin-top: $spacing--100;
            padding-top: $spacing--100;

            @media screen and (min-width: $breakpoint--300) {
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }
        }
    }

    &__action {
        display: inline-block;
        font-size: 2.5rem;
        padding: 0 $spacing--200;

        > span {
            cursor: pointer;
        }
    }

    .spark-drawer {

        &__title {
            &[class] {
                color: $color__blue--600;
            }
            &[aria-expanded=true] {
                min-height: ($spacing--600 + math.div($spacing--600, 2)) * 2;
                position: relative;

                @extend %icon--pointer;
                &:before {
                    font-family: $font__family--icon;
                    font-size: 2em;
                    color: #FFF;
                    position: absolute;
                    bottom: 22px;
                    left: -12px;
                    z-index: 12;
                    text-shadow: 0 -2px 0 #D0D3D4;
                }
            }

            &[aria-expanded=true]+.spark-drawer__container {
                max-height: $spacing--600 + math.div($spacing--600, 2);
                min-height: $spacing--600 + math.div($spacing--600, 2);
                border-top: 1px solid $color__black--400;
                border-bottom: 1px solid $color__black--400;
            }
        }

        &__container {
            text-align: left;
            position: absolute;
            top: $spacing--600 + math.div($spacing--600, 2);
            left: 0;
            max-width: 100%;
            background: $color__white;
            font-size: 1.5rem;
        }
    }

    &--end {
        display: flex;
        width: 100%;
        text-align: left;

        @media screen and (min-width: $breakpoint--400) {
            justify-content: flex-end;
        }

        .spark-content-actions__section {
            flex-shrink: 0;
            margin: 0;
            padding: 0;

            &:nth-child(2) {
                color: $color__black--600;
            }
        }
    }

}
