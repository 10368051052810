.spark-breadcrumb {
    width: 100vw;
    background-color: $color__white;
    padding: $spacing--100 $spacing--500;
    color: $color__black--500;
    font-weight: 100;
    font-family: $font__family--sans;
    border-bottom: 1px solid $color__black--300;

    padding-#{$dir-start}: lumen-gutter(300, true);
    @media (min-width: $breakpoint--400){
        padding-#{$dir-start}: lumen-gutter(400, true);
    }
    @media (min-width: $breakpoint--500){
        padding-#{$dir-start}: lumen-gutter(500, true);
    }
    @media (min-width: $breakpoint--600){
        padding-#{$dir-start}: lumen-gutter(600, true);
    }
    @media (min-width: $breakpoint--700){
        padding-#{$dir-start}: lumen-gutter(700, true);
    }

    &__divider {
        color: $color__black--300;
        border-bottom: none;
    }

    &__link {
        text-transform: uppercase;
        font-size: $font__size--200;
        font-weight: 700;
        letter-spacing: 1px;
        padding: 0 $spacing--100;

        &:first-child {
            padding-left: 0;
        }
    }

    &__current {
        color: $color__black--700;
        font-size: $font__size--300;
        padding: 0 $spacing--100;
    }

    .lumen-header &[class] {
        margin-#{$dir-start}: 0;
    }
}

@mixin spark-breadcrumb--narrow(){
    .spark-breadcrumb {
        margin-#{$dir-start}: - lumen-gutter(300, true);
        @media (min-width: $breakpoint--400){
            margin-#{$dir-start}: - lumen-columns(1,400, true) - lumen-gutter(400, true);
        }
        @media (min-width: $breakpoint--500){
            margin-#{$dir-start}: - lumen-columns(2,500, true) - lumen-gutter(500, true);
        }
        @media (min-width: $breakpoint--600){
            margin-#{$dir-start}: - lumen-columns(3,600, true) - lumen-gutter(600, true);
        }
        @media (min-width: $breakpoint--700){
            margin-#{$dir-start}: - lumen-columns(5,700, true) - lumen-gutter(700, true);
        }
    }
}

@mixin spark-breadcrumb--full(){
    .spark-breadcrumb {
        margin-#{$dir-start}: - lumen-gutter(300, true);
        @media (min-width: $breakpoint--400){
            margin-#{$dir-start}: - lumen-gutter(400, true);
        }
        @media (min-width: $breakpoint--500){
            margin-#{$dir-start}: - lumen-gutter(500, true);
        }
        @media (min-width: $breakpoint--600){
            margin-#{$dir-start}: - lumen-gutter(600, true);
        }
        @media (min-width: $breakpoint--700){
            margin-#{$dir-start}: - lumen-gutter(700, true);
        }
    }
}
