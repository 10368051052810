// lumen-sass-colors
// this file sets all the variables colors from Lumen

// GREYS/WHITE/BLACK
$color__white: #FFFFFF;
$color__white--translucent: rgba($color__white, 0.5);

$color__black--200: #F1F1F3;
$color__black--300: #E6E6E8;
$color__black--400: #D0D3D4;
$color__black--500: #A2AAAD;
$color__black--600: #97999b;
$color__black--700: #53565A;
$color__black--800: #333333;

$color__black--translucent: rgba(#000, 0.3);
$color__black--translucent--dark: rgba(#000, 0.7);
$color__black--800t: rgba(darken($color__black--800, 5%), 0.95);

// COLORS
$color__blue--400: #6BCDED;
$color__blue--500: #1AA8DE; //#1BA0D1;
$color__blue--600: #147EA7;
$color__blue--700: #13617F;

$color__green--400: #72C7B2;
$color__green--500: #23B389; //#17A883;
$color__green--600: #0C8667;
$color__green--700: #14664E;

$color__orange--400: #F9BA81;
$color__orange--500: #F88E24;
$color__orange--600: #E58533;

$color__red--400: #E3827E;
$color__red--500: #D1302F;
$color__red--600: #BC242D;
$color__red--700: #AD232C;

$color__yellow--400: #FDDD6E;
$color__yellow--500: #FBC70D;
$color__yellow--600: #F4A831;

//utility colors
$color__utility--red: #BC242D;
$color__utility--green: #0C8667;

//SOCIAL COLORS

$color__twitter: #55acee;
$color__facebook: #3b5998;
$color__pinterest: #cb2027;
$color__google: #dd4b39;
$color__instagram: #3C5A96;
$color__youtube: #cd201f;
$color__rss: $color__yellow--500;
$color__ok: #EE7808;
$color__vk: #507299;
$color__kakao: #000;
$color__whatsapp: #25D366;


// The colors below should only be used on VIO APPROVED TEMPLATES.
// GREYS/WHITE/BLACK
$color__black--600-gvsg: #888b8d;

// COLORS
$color__blue--400-gvsg: #9ADBE8;
$color__blue--500-gvsg: #3EB1C8;
$color__blue--600-gvsg: #005F83;
$color__blue--700-gvsg: #003057;

$color__green--400-gvsg: #B7BF10;
$color__green--500-gvsg: #6BA539;
$color__green--600-gvsg: #44883E;
$color__green--700-gvsg: #395542;

$color__orange--400-gvsg: #FFB81C;
$color__orange--500-gvsg: #F68D2E;
$color__orange--600-gvsg: #BE531C;
$color__orange--700-gvsg: #5C4738;

$color__red--400-gvsg: #DF4661;
$color__red--500-gvsg: #AC145A;
$color__red--600-gvsg: #872651;
$color__red--700-gvsg: #651D32;

//SOCIAL COLORS
$color__rss-gvsg: $color__orange--500;
